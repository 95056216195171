import { Grid, Typography } from '@mui/material';
import SuccessIcon from './SuccessIcon';

interface ToastSuccessProps {
  message: string;
}

export default function ToastSuccess({ message }: ToastSuccessProps) {
  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={2}>
        <SuccessIcon fontSize="large" />
      </Grid>
      <Grid item xs={10}>
        <Typography
          sx={{
            font: 'Brandon Text W01 Light',
            textDecoration: 'none',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            color: '#333333',
          }}
        >
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
}
