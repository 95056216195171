import { Grid, Typography } from '@mui/material';
import { formatSsn } from 'src/common/utils/ssnUtil';
import Claimant from 'src/users/models/Claimant';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';

interface searchSuggestionProps {
  claimant: Claimant;
}

export default function ClaimantSearchSuggestion({ claimant }: searchSuggestionProps) {
  const handleOpenOrgInNewTab = (orgId: string | null): void => {
    window?.open(`/?eigandi=${orgId}`, '_blank')?.focus();
  };
  if (claimant.organizationSsn) {
    return (
      <Grid container>
        <Grid item xs={8}>
          <Typography noWrap variant="body2">
            {claimant.number} - {claimant.name} (kt. {formatSsn(claimant.ssn)})
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'right' }}>
          <Typography
            className="orgLink"
            sx={{ flexGrow: 0 }}
            noWrap
            align="right"
            variant="body2"
            onClick={() => handleOpenOrgInNewTab(claimant.organizationId)}
          >
            <AccountTreeOutlinedIcon sx={{ fontSize: 16 }}></AccountTreeOutlinedIcon>
            &nbsp;{claimant.organizationName}
          </Typography>
        </Grid>
      </Grid>
    );
  } else if (claimant.name === 'Velja alla') {
    return (
      <Typography noWrap variant="body1">
        {claimant.number} - Velja allt
      </Typography>
    );
  } else {
    return (
      <Typography noWrap variant="body2">
        {claimant.number} - {claimant.name} (kt. {formatSsn(claimant.ssn)})
      </Typography>
    );
  }
}
