import { Edit } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import TextInput from './TextInput';

interface EditableTextInputProps {
  value: string;
  updateValue: (newValue: string | null) => void;
  validator?: (value: string) => boolean;
  errorText?: string | null;
}
export default function EditableTextInput({
  value,
  updateValue,
  validator = () => true,
  errorText = '',
}: EditableTextInputProps) {
  const ref = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState<string>(value);
  const [isValid, setIsValid] = React.useState(true);
  const [shouldShowHelperText, setShouldShowHelperText] = React.useState(false);

  useEffect(() => {
    if (isEditing) {
      ref.current?.focus();
    }
  }, [isEditing]);

  const onDataChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const val = event.target.value;
    const isValidValue = validator(val);
    setIsValid(isValidValue);
    setData(val);
    if (isValidValue) {
      setShouldShowHelperText(false);
    }
  };

  const handleSaveChange = (): void => {
    if (isValid) {
      setIsEditing(false);
      updateValue(data);
    } else {
      setShouldShowHelperText(true);
    }
  };

  const handleCancelEdit = (): void => {
    setData(value);
    setShouldShowHelperText(false);
    handleToggleEdit();
  };

  const handleToggleEdit = (): void => {
    setIsEditing(!isEditing);
  };

  return (
    <div style={{ display: 'flex' }}>
      {isEditing ? (
        <>
          <TextInput
            inputRef={ref}
            error={shouldShowHelperText && !isValid}
            disabled={!isEditing}
            onChange={onDataChange}
            value={data}
            helperText={shouldShowHelperText ? errorText : ''}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSaveChange();
              } else if (e.key === 'Escape') {
                handleCancelEdit();
                e.stopPropagation();
              }
            }}
          />
        </>
      ) : (
        <>
          <Typography fontFamily="Brandon Text W01 Regular" fontWeight="medium">
            {data ?? value}
          </Typography>
          <IconButton sx={{ marginTop: '-0.25rem' }} onClick={handleToggleEdit}>
            <Edit sx={{ fontSize: '1rem' }} />
          </IconButton>
        </>
      )}
    </div>
  );
}
