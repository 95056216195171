import { Button, Typography } from '@mui/material';
import { ReactNode } from 'react';
import usePermissions from 'src/common/hooks/authenticationHook';

interface CreateButtonProps {
  text: string;
  icon: ReactNode;
  handleOpenCreateModal: () => void;
}

export default function CreateButton({ text, icon, handleOpenCreateModal }: CreateButtonProps) {
  const { isAllowedToSave } = usePermissions();
  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={!isAllowedToSave}
      sx={{
        backgroundColor: 'white',
        borderRadius: 1,
        textTransform: 'none',
        fontWeight: 400,
        opacity: `${isAllowedToSave ? 1 : 0.5}`,
      }}
      startIcon={icon}
      onClick={handleOpenCreateModal}
    >
      <Typography color="primary.dark">{text}</Typography>
    </Button>
  );
}
