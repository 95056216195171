import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid, Link } from '@mui/material';
import Panel from 'src/common/UI/layouts/Panel';
import SearchInput from 'src/common/UI/forms/SearchInput';
import TextInput from 'src/common/UI/forms/TextInput';
import LoadingSpinner from 'src/common/UI/loading/LoadingSpinner';
import NationalRegistryEntry from 'src/common/models/NationalRegistryEntry';
import NationalRegistryService from 'src/common/services/nationalRegistryService';
import { toast } from 'react-toastify';
import ClaimantsPanel from './OrganizationClaimantsPanel';
import ToastSuccess from 'src/common/UI/toasts/ToastSuccess';
import Banner from 'src/common/UI/banners/Banner';
import usePermissions from 'src/common/hooks/authenticationHook';
import { formatSsn } from 'src/common/utils/ssnUtil';
import { Organization } from 'src/organizations/models/Organization';
import { CreateOrganizationRequest } from 'src/organizations/models/CreateOrganizationRequest';
import { trpcClient } from 'pages/api/trpc/_api';
import ProductsPanel from 'src/organizations/UI/upsertOrganization/ProductsPanel';

const styles = {
  confirmButton: {
    backgroundColor: '#375046',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '8px',
    padding: '10px 22px 11px 25px',
  },
  cancelButton: {
    color: '#666666',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '7px',
    border: '1px solid #666666',
    padding: '10px 16px',
  },
  foreignLink: {
    color: '#73AF69',
    fontWeight: 400,
    textDecoration: 'none',
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
};

const defaultOrg: Organization = {
  id: '',
  ssn: undefined,
  name: '',
  description: '',
  isActive: true,
  claimantNumbers: [],
  createdDate: '',
  canGetAccessToOtherClaimants: false,
  hasAccessToCollection: true,
  hasAccessToClaimCreation: false,
};

interface Props {
  onSuccess: () => void;
  onFailure: () => void;
  onCancel: () => void;
  onSubmit: () => void;
}

export default function CreateOrganizationForm({ onSuccess, onFailure, onCancel, onSubmit }: Props) {
  const [isForeign, setIsForeign] = useState(false);
  const [nationalRegistryEntry, setNationalRegistryEntry] = useState<NationalRegistryEntry | null>(null);
  const [description, setDescription] = useState<string>('');
  const [claimants, setClaimants] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSsnUnavailable, setIsSsnUnavailable] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [organization, setOrganization] = useState<Organization>(defaultOrg);
  const { isAllowedToSave } = usePermissions();
  const nationalRegistryService = new NationalRegistryService();
  const [hasAccessToCollection, setHasAccessToCollection] = useState(true);
  const [hasAccessToClaimCreation, setHasAccessToClaimCreation] = useState(false);

  const clearSsnError = (): void => {
    setIsSsnUnavailable(false);
    setErrorMessage('');
  };

  async function handleNationalRegistryEntryChange(newEntry: NationalRegistryEntry | null) {
    setNationalRegistryEntry(newEntry);

    if (!newEntry) setOrganization(defaultOrg);
    else {
      setIsSaving(true);

      await trpcClient.organizations.getOrganizationBySsn
        .query(newEntry.ssn)
        .then((orgs) => {
          if (orgs.length == 0) {
            clearSsnError();
            setOrganization({
              ...organization,
              ssn: newEntry.ssn,
              name: newEntry.name,
            });
          } else {
            setIsSsnUnavailable(true);
            setErrorMessage(`Það er nú þegar til eigandi með kennitöluna ${formatSsn(newEntry.ssn)}.`);
          }
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  }

  const onIsForeignChange = (): void => {
    clearSsnError();
    setNationalRegistryEntry(null);
    setOrganization(defaultOrg);

    setIsForeign((prevState) => !prevState);
  };

  const onForeignNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const entry = new NationalRegistryEntry();
    entry.name = event.target.value;
    setNationalRegistryEntry(entry);
  };

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setDescription(event.target.value);
  };

  const isStateValid = (): boolean => {
    return nationalRegistryEntry !== null;
  };

  const handleConfirm = (): void => {
    if (!nationalRegistryEntry || isSaving || !isAllowedToSave) return;
    setIsSaving(true);
    onSubmit();
    const org: CreateOrganizationRequest = {
      ssn: !isForeign ? nationalRegistryEntry?.ssn : undefined,
      name: nationalRegistryEntry?.name,
      description: description,
      claimantNumbers: claimants,
      hasAccessToCollection: hasAccessToCollection,
      hasAccessToClaimCreation: hasAccessToClaimCreation,
    };
    trpcClient.organizations.createOrganization
      .mutate(org)
      .then(
        () => {
          toast(<ToastSuccess message={'Eigandi hefur verið vistaður.'} />);
          onSuccess();
        },
        () => {
          setErrorMessage('Hér hefur eitthvað farið úrskeiðis');
          onFailure();
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };
  const onDismissError = () => {
    setErrorMessage('');
  };

  return (
    <>
      <Box sx={{ mb: -3 }}>
        <Typography variant="h1" display="inline" sx={{ marginRight: '12px' }}>
          Stofna eiganda
        </Typography>
      </Box>
      <Panel label="Eigandi">
        <Box sx={{ mb: 3 }} />
        <Grid container rowSpacing="34px" columnSpacing="128px">
          <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
            <Link href={'#'} onClick={onIsForeignChange} sx={styles.foreignLink}>
              {isForeign ? 'Íslenskur aðili' : 'Erlendur aðili'}
            </Link>
            {isForeign ? (
              <TextInput label="Sláðu inn nafn aðila" onChange={onForeignNameChange} />
            ) : (
              <SearchInput<NationalRegistryEntry>
                searchSuggestionService={nationalRegistryService}
                onValue={handleNationalRegistryEntryChange}
                label="Sláðu inn nafn eða kennitölu aðila"
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput label="Skrá aukaheiti" onChange={onDescriptionChange} />
          </Grid>
        </Grid>
      </Panel>
      <ProductsPanel
        hasAccessToCollection={hasAccessToCollection}
        setHasAccessToCollection={setHasAccessToCollection}
        hasAccessToClaimCreation={hasAccessToClaimCreation}
        setHasAccessToClaimCreation={setHasAccessToClaimCreation}
      />
      {isSaving ? (
        <LoadingSpinner />
      ) : (
        <>
          {nationalRegistryEntry?.name !== '' ? (
            <ClaimantsPanel
              organizationOrUser={organization}
              onClaimantsChange={setClaimants}
              isCreatingOrganization={true}
            />
          ) : null}
        </>
      )}

      <Panel label="">
        {errorMessage && (
          <Grid container justifyContent="flex-end" spacing={2} sx={{ mb: 2 }}>
            <Banner type="error" onDismissed={onDismissError}>
              {errorMessage}
            </Banner>
          </Grid>
        )}
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" sx={styles.cancelButton} onClick={onCancel}>
              Hætta við
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={isSaving || !isStateValid() || !isAllowedToSave || isSsnUnavailable}
              sx={styles.confirmButton}
              onClick={handleConfirm}
            >
              Vista
            </Button>
          </Grid>
        </Grid>
      </Panel>
    </>
  );
}
