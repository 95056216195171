import { SearchInputOption } from 'src/common/UI/forms/SearchInput';
import { formatSsn } from 'src/common/utils/ssnUtil';

export interface IClaimant extends SearchInputOption {
  number: string;
  name: string;
  name2: string;
  ssn: string;
  supervisorSsn: string;
  organizationName: string | null;
  organizationSsn: string | null;
  organizationId: string | null;
}

export default class Claimant implements IClaimant {
  constructor() {
    this.number = '';
    this.name = '';
    this.name2 = '';
    this.ssn = '';
    this.supervisorSsn = '';
    this.organizationName = null;
    this.organizationSsn = null;
    this.organizationId = null;
  }
  number: string;
  name: string;
  name2: string;
  ssn: string;
  supervisorSsn: string;
  organizationName: string | null;
  organizationSsn: string | null;
  organizationId: string | null;
  getKey = (): string => this.ssn;
  getLabel = (): string => `${this.number} - ${this.name} (kt. ${formatSsn(this.ssn)})`;
  compare = (other: unknown): boolean => {
    return this.ssn === (other as Claimant).ssn;
  };
}
