import { Button, FormControlLabel, Grid, Typography } from '@mui/material';
import { DataState } from 'src/common/hooks/useDataApi';
import { formatSsn } from 'src/common/utils/ssnUtil';
import AccordionComposite from 'src/common/UI/layouts/AccordionComposite';
import DeleteButton from 'src/common/UI/buttons/DeleteButton';
import CircularProgressButton from 'src/common/UI/buttons/CircularProgressButton';
import ClearOutlinedButton from 'src/common/UI/buttons/ClearOutlinedButton';
import { handleTogglePanel, areAllPanelsExpanded } from 'src/common/UI/layouts/AccordionComposite';
import { ClaimantsOrganizationState, ClaimantState } from './models';

interface ClaimantAccordionProps {
  isAllowedToSave: boolean;
  claimantsData: DataState<{ [ssno: string]: ClaimantsOrganizationState }>;
  expandedPanels: { [index: string]: boolean };
  setExpandedPanels: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  removeClaimant: (claimantNumber: string, ssno: string) => void;
  removeOrganization: (e: React.SyntheticEvent | null, ssno: string) => void;
}

export default function ClaimantAccordion({
  isAllowedToSave,
  claimantsData,
  expandedPanels,
  setExpandedPanels,
  removeClaimant,
  removeOrganization,
}: ClaimantAccordionProps) {
  const claimantSSNOs = Object.keys(claimantsData.data);

  function toggleExpandedPanels() {
    const shouldOpenPanels = !areAllPanelsExpanded(expandedPanels);
    const newExpandedPanels = mapExpandedPanels(shouldOpenPanels);
    setExpandedPanels(newExpandedPanels);
  }

  function mapExpandedPanels(isExpanded: boolean) {
    const newExpandedPanels: { [index: string]: boolean } = {};
    for (let i = 0; i < claimantSSNOs.length; i++) {
      const index: string = claimantSSNOs[i];
      newExpandedPanels[index] = isExpanded;
    }

    return newExpandedPanels;
  }

  function claimantAccordionTitle(ssno: string) {
    const claimantStates = claimantsData.data[ssno].claimantStates;
    const name = claimantStates[0].claimant.name2;
    return `${name} ${formatSsn(ssno)} (${claimantStates.length} kröfuhafanúmer)`;
  }

  return (
    <>
      <Grid container>
        {claimantSSNOs.length > 0 && (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', mt: 0.5 }}>
            <Button onClick={toggleExpandedPanels} sx={{ pt: 0, textTransform: 'none' }} variant="text">
              <Typography>{areAllPanelsExpanded(expandedPanels) ? 'Loka öllum' : 'Opna alla'}</Typography>
            </Button>
          </Grid>
        )}
      </Grid>
      <AccordionComposite
        hasAccess={true}
        contents={claimantSSNOs}
        expandedPanels={expandedPanels}
        setExpandedPanels={setExpandedPanels}
        onPanelToggle={handleTogglePanel}
        summary={(ssno: string) => {
          return (
            <Grid container>
              <Grid item xs={11} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography variant="body2">{claimantAccordionTitle(ssno)}</Typography>
              </Grid>
              <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {claimantsData.data[ssno].isRemoving ? (
                    <CircularProgressButton sx={{ p: 0, marginRight: 1 }} />
                  ) : (
                    <DeleteButton
                      sx={{ p: 0 }}
                      isDisabled={!isAllowedToSave}
                      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => removeOrganization(e, ssno)}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        }}
        details={(ssn: string) => {
          return (
            <Grid container>
              {claimantsData.data[ssn].claimantStates.map((state: ClaimantState, id2: number) => (
                <Grid item xs={12} lg={6} key={id2 + '-claimant'}>
                  <FormControlLabel
                    labelPlacement={'start'}
                    key={id2 + '-form'}
                    label={
                      <Typography variant="body2" display="inline">
                        {state.claimant.number} {state.claimant.name ? '- ' + state.claimant.name : ''}
                      </Typography>
                    }
                    control={
                      state.isRemoving ? (
                        <CircularProgressButton />
                      ) : (
                        <ClearOutlinedButton
                          isDisabled={!isAllowedToSave}
                          onClick={() => removeClaimant(state.claimant.number, ssn)}
                        />
                      )
                    }
                  />
                </Grid>
              ))}
            </Grid>
          );
        }}
      />
    </>
  );
}
