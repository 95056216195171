import Box from '@mui/material/Box';
import Header from './Header';
import constants from 'src/common/utils/constants';

export default function Layout() {
  return (
    <Box
      sx={{
        ml: `calc(6% + ${constants.DRAWER_WIDTH}px)`,
        mr: '5%)',
        minWidth: '600px',
        mb: 2,
      }}
    >
      <Header></Header>
    </Box>
  );
}
