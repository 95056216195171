import Typography from '@mui/material/Typography';

interface OrganizationCountProps {
  organizationCount: number;
}

export default function HeaderPage({ organizationCount }: OrganizationCountProps): JSX.Element {
  return (
    <Typography variant="body2">Fjöldi eigenda {organizationCount >= 1000 ? '1000+' : organizationCount}</Typography>
  );
}
