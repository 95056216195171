import { FormControlLabel, Switch } from '@mui/material';
import usePermissions from 'src/common/hooks/authenticationHook';
import { ActivityState } from 'src/common/models/ActivityState';

interface ActivationButtonProps {
  activityState: ActivityState;
  handleActivation: () => void;
}

export default function ActivationButton({ activityState, handleActivation }: ActivationButtonProps) {
  const { isAllowedToSave } = usePermissions();
  return (
    <FormControlLabel
      disabled={!isAllowedToSave}
      control={
        <Switch
          onClick={handleActivation}
          sx={{
            ml: 2,
            mb: 1,
            width: 120,
            height: 50,
            '& .MuiSwitch-switchBase': {
              mt: 0.6,

              transform: 'translateX(5px)',
              '&.Mui-checked': {
                transform: 'translateX(75px)',
                color: 'white',
                '& +.MuiSwitch-track': {
                  opacity: 1,
                  backgroundColor: '#73AF69',
                  ...(activityState === 'Onboarding' && {
                    opacity: 0.7,
                    backgroundColor: '#283C32',
                  }),
                },
              },
            },
            '& .MuiSwitch-thumb': {
              width: 22,
              height: 22,
            },
            '& .MuiSwitch-track': {
              backgroundColor: '#CD5F3C',
              opacity: 1,
              borderRadius: 26 / 2,
              '&:before': {
                ml: 2,
                content: '"Virkur"',
                color: 'white',
                ...(activityState === 'Closed' && {
                  ml: 3.7,
                  content: '"Lokaður"',
                }),
                ...(activityState === 'Onboarding' && {
                  ml: 1.5,
                  content: '"Óvirkur"',
                }),
              },
            },
          }}
          checked={activityState !== 'Closed'}
        />
      }
      label={undefined}
    />
  );
}
