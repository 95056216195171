import * as React from 'react';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid } from '@mui/material';
import _ from 'lodash';
import { toast } from 'react-toastify';
import LoadingSpinner from 'src/common/UI/loading/LoadingSpinner';
import StatusChip from 'src/common/UI/dataDisplay/StatusChip';
import TextInput from 'src/common/UI/forms/TextInput';
import Panel from 'src/common/UI/layouts/Panel';
import { toDate } from 'src/common/utils/dateUtil';
import { formatSsn } from 'src/common/utils/ssnUtil';
import ClaimantsPanel from './OrganizationClaimantsPanel';
import Banner from 'src/common/UI/banners/Banner';
import ToastSuccess from 'src/common/UI/toasts/ToastSuccess';
import usePermissions from 'src/common/hooks/authenticationHook';
import { ModalCancelControl } from 'src/common/UI/layouts/ModalCancelControl';
import { api, trpcClient } from 'pages/api/trpc/_api';
import { Organization } from 'src/organizations/models/Organization';
import { noCacheAndNoRefetchOptions } from 'src/common/utils/noCacheAndNoRefetchOptions';
import ProductsPanel from 'src/organizations/UI/upsertOrganization/ProductsPanel';
import PartnerUserAccessPanel from './PartnerUserAccessPanel';
import { OrganizationPartner } from 'src/organizations/models/OrganizationPartner';
import { PartnerSchemaType } from 'src/organizations/models/UpdateOrganizationPartner';

const styles = {
  confirmButton: {
    backgroundColor: '#375046',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '8px',
    padding: '10px 22px 11px 25px',
  },
  cancelButton: {
    color: '#666666',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '7px',
    border: '1px solid #666666',
    padding: '10px 16px',
  },
  foreignLink: {
    color: '#73AF69',
    fontWeight: 400,
    textDecoration: 'none',
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
};

interface Props {
  organizationId: string;
  onSuccess: () => void;
  onFailure: () => void;
  onSubmit: () => void;
  modalCancelControl: ModalCancelControl;
}

interface Partner {
  id: string;
  claimTemplates: string[];
}

interface Templates {
  [key: string]: string[];
}

export default function EditOrganizationForm({
  organizationId,
  onSuccess,
  onFailure,
  modalCancelControl,
  onSubmit,
}: Props) {
  const [description, setDescription] = useState<string>('');
  const [claimantNumbers, setClaimantNumbers] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const { isAllowedToSave } = usePermissions();
  const organizationState = api.organizations.getOrganization.useQuery(organizationId, noCacheAndNoRefetchOptions);
  const [hasAccessToCollection, setHasAccessToCollection] = useState<boolean>(false);
  const [hasAccessToClaimCreation, setHasAccessToClaimCreation] = useState<boolean>(false);
  const [claimTemplate] = useState<string[]>([]);
  const organizationPartnerState = api.organizations.getOrganizationPartner.useQuery<OrganizationPartner[]>(
    organizationId || '',
    {
      ...noCacheAndNoRefetchOptions,
      enabled: !!organizationId,
    }
  );
  const [selectedClaimTemplates, setSelectedClaimTemplates] = useState<{ [partnerId: string]: string[] }>({});

  useEffect(() => {
    if (organizationState.data) {
      setDescription(organizationState.data.description);
      setClaimantNumbers(organizationState.data.claimantNumbers);
      setHasAccessToCollection(organizationState.data.hasAccessToCollection);
      setHasAccessToClaimCreation(organizationState.data.hasAccessToClaimCreation);
    }
  }, [organizationState.data]);

  useEffect(() => {
    if (organizationPartnerState.data) {
      const initialSelectedClaimTemplates = organizationPartnerState.data.reduce<{ [key: string]: string[] }>(
        (acc, partner) => {
          acc[partner.id] = partner.claimTemplates;
          return acc;
        },
        {}
      );
      setSelectedClaimTemplates(initialSelectedClaimTemplates);
    }
  }, [organizationPartnerState.data]);

  const handleCheckboxChange = React.useCallback((partnerId: string, claimTemplateId: string, isChecked: boolean) => {
    setSelectedClaimTemplates((prevState) => {
      const selectedTemplatesForPartner = prevState[partnerId] ? [...prevState[partnerId]] : [];
      const newSelectedTemplatesForPartner = isChecked
        ? [...selectedTemplatesForPartner, claimTemplateId]
        : selectedTemplatesForPartner.filter((id) => id !== claimTemplateId);
      return { ...prevState, [partnerId]: newSelectedTemplatesForPartner };
    });
  }, []);

  const forceCancelEdit = () => {
    modalCancelControl.isSafe = true;
    modalCancelControl.closeFunc();
  };

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setDescription(event.target.value);
  };

  const handleClaimantsChange = (claimants: string[]) => {
    setClaimantNumbers(claimants);
  };

  const handleAddNewClaimant = (claimant: string) => {
    setClaimantNumbers((prevState) => {
      return [...prevState, claimant];
    });
  };

  if (organizationState.isLoading) {
    return <LoadingSpinner></LoadingSpinner>;
  } else if (organizationState.isError || !organizationState.data) {
    return <Banner type="error">{organizationState.error.message}</Banner>;
  }

  const isStateValid = () => {
    return !organizationState.data;
  };

  // Transform the partner data to a format that can be compared with the selected claim templates
  const transformPartnerData = (data: Partner[] | undefined): Templates =>
    (data || []).reduce((acc: Templates, partner: Partner) => {
      acc[partner.id] = partner.claimTemplates;
      return acc;
    }, {});

  const transformSelectedTemplates = (templates: Templates): Templates =>
    Object.keys(templates).reduce((acc: Templates, key: string) => {
      acc[key] = templates[key];
      return acc;
    }, {});

  const comparePartners = (original: Templates, selected: Templates): boolean =>
    Object.keys(selected).every((key) => _.isEqual(original[key], selected[key]));

  const transformedOrganizationPartnerState = transformPartnerData(organizationPartnerState.data);
  const transformedSelectedClaimTemplates = transformSelectedTemplates(selectedClaimTemplates);
  const isClickedPartnersAreEqual = comparePartners(
    transformedOrganizationPartnerState,
    transformedSelectedClaimTemplates
  );

  const hasOrganizationBeenModified = () => {
    const hasBeenModified =
      description !== organizationState.data?.description ||
      !_.isEmpty(_.xor(claimantNumbers, organizationState.data?.claimantNumbers)) ||
      hasAccessToCollection !== organizationState.data?.hasAccessToCollection ||
      hasAccessToClaimCreation !== organizationState.data?.hasAccessToClaimCreation ||
      !isClickedPartnersAreEqual;

    if (hasBeenModified) {
      modalCancelControl.isSafe = false;
    } else {
      modalCancelControl.isSafe = true;
    }
    return hasBeenModified;
  };

  const saveOrganizationPartners = (partners: PartnerSchemaType[]): void => {
    if (!organizationPartnerState.data) {
      setErrorMessage('Samfstarfsaðila gögn tóm');
      return;
    }
    if (!isAllowedToSave || isSaving) return;

    setIsSaving(true);
    trpcClient.organizations.updateOrganizationPartner
      .mutate({ organizationId: organizationId, partners })
      .then(
        () => {
          onSuccess();
        },
        (error) => {
          console.log('Error saving organization partners:', error);
          onFailure();
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  const saveOrganization = (): void => {
    if (!organizationState.data) {
      setErrorMessage('Eigenda gögn tóm');
      return;
    }
    if (!isAllowedToSave || isSaving) return;

    setIsSaving(true);
    onSubmit();

    const org: Organization = {
      ...(organizationState.data as Organization),
      description,
      claimantNumbers: claimantNumbers,
      hasAccessToCollection,
      hasAccessToClaimCreation,
    };

    trpcClient.organizations.updateOrganization
      .mutate(org)
      .then(() => {
        toast(<ToastSuccess message={'Eigandi hefur verið uppfærður.'} />);
        onSuccess();

        // Prepare the data for the organization partners
        const partners = organizationPartnerState.data?.map((partner) => {
          // Determine the final list of claim templates for each partner, accounting for deselections
          const finalClaimTemplates = partner.claimTemplates
            .filter((template) => selectedClaimTemplates[partner.id]?.includes(template)) // Keep if still selected
            .concat(
              selectedClaimTemplates[partner.id]?.filter((template) => !partner.claimTemplates.includes(template)) || []
            );
          return {
            partnerId: partner.id,
            claimants: partner.claimants,
            claimTemplates: finalClaimTemplates,
          };
        });

        if (partners) {
          saveOrganizationPartners(partners);
        }
      })
      .catch(() => {
        setErrorMessage('Hér hefur eitthvað farið úrskeiðis');
        onFailure();
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  return (
    <>
      <Box sx={{ mb: -3 }}>
        <Typography variant="h1" display="inline" sx={{ marginRight: '12px' }}>
          {organizationState.data.name}
        </Typography>
        <Typography variant="body2" display="inline">{`kt. ${formatSsn(organizationState.data.ssn)}`}</Typography>
        <StatusChip label="Virkur" activityState={'Active'} />
      </Box>
      <Panel label="Eigandi">
        <Box sx={{ mb: 3 }} />
        <Grid container rowSpacing="34px" columnSpacing="128px">
          <Grid item xs={12} md={6}>
            <Typography>Aðgangur stofnaður</Typography>
            <Typography fontWeight={100}>{toDate(organizationState.data.createdDate)}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              disabled={!isAllowedToSave}
              label="Skrá aukaheiti"
              onChange={onDescriptionChange}
              value={description}
            />
          </Grid>
        </Grid>
      </Panel>
      <ProductsPanel
        hasAccessToCollection={hasAccessToCollection}
        setHasAccessToCollection={setHasAccessToCollection}
        hasAccessToClaimCreation={hasAccessToClaimCreation}
        setHasAccessToClaimCreation={setHasAccessToClaimCreation}
      />
      <ClaimantsPanel
        organizationOrUser={organizationState.data}
        isCreatingOrganization={false}
        onClaimantsChange={handleClaimantsChange}
        onAddNewClaimant={handleAddNewClaimant}
      ></ClaimantsPanel>
      {organizationPartnerState.data?.length != 0 && (
        <PartnerUserAccessPanel
          organizationPartners={organizationPartnerState?.data}
          organizationId={organizationState?.data.id}
          organization={organizationState?.data}
          claimTemplates={claimTemplate}
          handleCheckboxChange={handleCheckboxChange}
        />
      )}
      <Panel label="">
        {errorMessage && (
          <Grid container justifyContent="flex-end" spacing={2} sx={{ mb: 2 }}>
            <Banner type="error">{errorMessage}</Banner>
          </Grid>
        )}
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" sx={styles.cancelButton} onClick={forceCancelEdit} disabled={isSaving}>
              Hætta við
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={styles.confirmButton}
              disabled={isSaving || (!hasOrganizationBeenModified() && !isStateValid()) || !isAllowedToSave}
              onClick={saveOrganization}
            >
              Vista
            </Button>
          </Grid>
        </Grid>
      </Panel>
    </>
  );
}
