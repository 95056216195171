import { SvgIcon, SvgIconProps } from '@mui/material';

export default function SuccessIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} fontSize="large" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <rect x="0.586914" y="0.657227" width="35.1917" height="34.903" rx="5" fill="#008024" />
      <g clipPath="url(#clip0_2045_4989)">
        <path d="M30.1826 6.1084H6.18262V30.1084H30.1826V6.1084Z" fill="white" fillOpacity="0.01" />
        <path
          d="M18.1826 28.1084C20.944 28.1084 23.444 26.9891 25.2537 25.1794C27.0633 23.3698 28.1826 20.8698 28.1826 18.1084C28.1826 15.347 27.0633 12.847 25.2537 11.0373C23.444 9.22769 20.944 8.1084 18.1826 8.1084C15.4212 8.1084 12.9212 9.22769 11.1115 11.0373C9.30191 12.847 8.18262 15.347 8.18262 18.1084C8.18262 20.8698 9.30191 23.3698 11.1115 25.1794C12.9212 26.9891 15.4212 28.1084 18.1826 28.1084Z"
          stroke="white"
          fill="none"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M14.1826 18.1084L17.1826 21.1084L23.1826 15.1084"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2045_4989">
          <rect width="24" height="24" fill="white" transform="translate(6.18262 6.1084)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
