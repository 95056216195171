import { Grid } from '@mui/material';
import useDataApi from 'src/common/hooks/useDataApi';
import Panel from 'src/common/UI/layouts/Panel';
import SearchInput from 'src/common/UI/forms/SearchInput';
import Claimant from 'src/users/models/Claimant';
import { useState, useEffect, useMemo } from 'react';
import { EmptyFunction } from 'src/common/utils/emptyFunction';
import { OrganizationSuggestion } from 'src/organizations/models/OrganizationSearchSuggestion';
import LoadingSpinner from 'src/common/UI/loading/LoadingSpinner';
import Banner from 'src/common/UI/banners/Banner';
import MultiMessageBanner from 'src/common/UI/banners/MultiMessageBanner';
import CheckboxTextRight from 'src/common/UI/checkboxes/CheckboxTextRight';
import ClaimantSearchService from 'src/users/services/claimantSearchService';
import ClaimantSearchSuggestion from 'src/organizations/UI/upsertOrganization/ClaimantSearchSuggestion';
import User from 'src/users/models/User';
import usePermissions from 'src/common/hooks/authenticationHook';
import { ClaimantsOrganizationState, ClaimantState } from './models';
import ClaimantAccordion from './ClaimantAccordion';
import {
  createClaimantsOrganizationState,
  getAllClaimantStates,
  getClaimantBelongsToOrgMessage,
  getIncludeOrphants,
  mapExpandedPanels,
} from './helpers';
import { trpcClient } from 'pages/api/trpc/_api';
import { Organization } from 'src/organizations/models/Organization';

interface EditClaimantProps {
  organizationOrUser: OrganizationSuggestion | Organization | User;
  isCreatingOrganization: boolean;
  canGetAccessToOtherClaimants?: boolean;
  onClaimantsChange: (claimants: string[]) => void;
  onAddNewClaimant?: (claimant: string) => void;
}

export default function OrgClaimantsPanel({
  organizationOrUser,
  onClaimantsChange: handleClaimantsChange,
  isCreatingOrganization,
  canGetAccessToOtherClaimants,
  onAddNewClaimant = EmptyFunction,
}: EditClaimantProps) {
  const isUpsertingUser = canGetAccessToOtherClaimants !== undefined;
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [infoMessages, setInfoMessages] = useState<string[]>([]);
  const [successMessages, setSuccessMessages] = useState<string[]>([]);
  const [shouldOnlySearchForOrphantClaimants, setShouldOnlySearchForOrphantClaimants] = useState(true);
  const [selectedClaimant, setSelectedClaimant] = useState<Claimant[] | null>(null);
  const [claimantsData, setData] = useDataApi<{ [ssno: string]: ClaimantsOrganizationState }>(() => getClaimants());

  const [expandedPanels, setExpandedPanels] = useState<{ [index: string]: boolean }>(
    mapExpandedPanels(Object.keys(claimantsData.data), false)
  );
  const { isAllowedToSave } = usePermissions();

  async function getClaimants(): Promise<{ [ssno: string]: ClaimantsOrganizationState }> {
    if (isCreatingOrganization) {
      return Promise.resolve({});
    } else if (canGetAccessToOtherClaimants) {
      return trpcClient.claimants.getUserClaimants.query(organizationOrUser.id).then((response) => {
        return createClaimantsOrganizationState(response);
      });
    } else {
      return trpcClient.claimants.getOrgClaimants.query(organizationOrUser.id).then((response) => {
        return createClaimantsOrganizationState(response);
      });
    }
  }

  const claimantSearchService = useMemo(
    () => new ClaimantSearchService(getIncludeOrphants(isUpsertingUser, shouldOnlySearchForOrphantClaimants)),
    [isUpsertingUser, shouldOnlySearchForOrphantClaimants]
  );

  function addError(message: string) {
    setErrorMessages((prevState) => [...prevState, message]);
  }

  function addInfo(message: string) {
    setInfoMessages((prevState) => [...prevState, message]);
  }

  async function removeError(index: number) {
    setErrorMessages((prevState) => prevState.filter((_, i) => i != index));
  }

  async function removeInfo(index: number) {
    setInfoMessages((prevState) => prevState.filter((_, i) => i != index));
  }

  function addSuccess(message: string) {
    setSuccessMessages((prevState) => [...prevState, message]);
  }

  function handleSetSelectedClaimant(value: Claimant | null, suggestions: Claimant[] | null) {
    if (value) {
      if (value.name === 'Velja alla' && suggestions) {
        const suggestionsWithoutTotalItem = suggestions.slice(2);
        setSelectedClaimant(suggestionsWithoutTotalItem);
      } else {
        setSelectedClaimant([value]);
      }
    }
  }

  function addClaimant(claimant: Claimant) {
    const newEntry: ClaimantState = { claimant: claimant, isRemoving: false };
    if (claimant.ssn in claimantsData.data) {
      claimantsData.data[claimant.ssn].claimantStates.push(newEntry);
    } else {
      claimantsData.data[claimant.ssn] = { isRemoving: false, claimantStates: [newEntry] };
    }

    setData(claimantsData.data);
  }

  useEffect(() => {
    const orgClaimantStates = getAllClaimantStates(claimantsData.data);
    if (selectedClaimant) {
      selectedClaimant.forEach((claimant) => {
        if (isClaimantAvailable(claimant, orgClaimantStates)) {
          handleAddNewClaimant(claimant);
        }
      });
    }
  }, [selectedClaimant]);

  useEffect(() => {
    handleClaimantsChange(getAllClaimantStates(claimantsData.data).map((x) => x.claimant.number));
  }, [claimantsData]);

  function handleAddNewClaimant(claimant: Claimant) {
    addClaimant(claimant);
    onAddNewClaimant(claimant.number);
    addSuccess(`${claimant.number + ' - ' + claimant.name}`);
    setExpandedPanels({ ...expandedPanels, [claimant.ssn]: true });
  }

  function isClaimantAvailable(claimant: Claimant, orgClaimantStates: ClaimantState[]) {
    if (orgClaimantStates.some((x) => x.claimant.number == claimant.number)) {
      addInfo(getClaimantBelongsToOrgMessage(organizationOrUser?.ssn, organizationOrUser.name));
      return false;
    }

    // The following restriction only applies when connecting organizations to claimants
    if (!isUpsertingUser && claimant.organizationSsn && claimant.organizationSsn !== organizationOrUser.ssn) {
      addError(getClaimantBelongsToOrgMessage(claimant.organizationSsn, claimant.organizationName));
      return false;
    }

    return true;
  }

  async function removeOrganization(e: React.SyntheticEvent | null, ssno: string) {
    e ? e.stopPropagation() : null;

    claimantsData.data[ssno].isRemoving = true;
    setData(claimantsData.data);

    delete claimantsData.data[ssno];
    setData(claimantsData.data);
  }

  async function removeClaimant(claimantNumber: string, ssno: string) {
    const claimantToRemove = claimantsData.data[ssno].claimantStates.find((x) => x.claimant.number == claimantNumber);
    if (claimantToRemove) {
      claimantToRemove.isRemoving = true;
      setData(claimantsData.data);

      claimantToRemove.isRemoving = false;
      setData(claimantsData.data);

      if (claimantsData.data[ssno].claimantStates.length > 1) {
        claimantsData.data[ssno].claimantStates = claimantsData.data[ssno].claimantStates.filter(
          (x) => x != claimantToRemove
        );
      } else {
        delete claimantsData.data[ssno];
      }
      setData(claimantsData.data);
    }
  }

  if (claimantsData.isLoading) {
    return <LoadingSpinner />;
  } else if (claimantsData.isError) {
    return <Banner type="error">{claimantsData.errorMessage}</Banner>;
  }

  return (
    <Panel label={isUpsertingUser ? 'Kröfuhafar' : 'Tengist félögum'}>
      <Grid container>
        <Grid item xs={12}>
          <SearchInput<Claimant>
            searchSuggestionService={claimantSearchService}
            onValue={handleSetSelectedClaimant}
            label="Sláðu inn nafn eða kennitölu aðila"
            hasAutoSelect={false}
            ignoreEventsOnTarget="orgLink"
            renderSuggestion={(claimant) => {
              return <ClaimantSearchSuggestion claimant={claimant} />;
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 4, mt: 1 }}>
          {!isUpsertingUser && (
            <CheckboxTextRight
              isChecked={shouldOnlySearchForOrphantClaimants}
              onChange={(event) => setShouldOnlySearchForOrphantClaimants(event.target.checked)}
              sx={{ mt: -0.5 }}
              isDisabled={!isAllowedToSave}
              text={'Skoða einungis án eigenda'}
            />
          )}
        </Grid>
      </Grid>
      <>
        {errorMessages.map((message, index) => (
          <Banner
            sx={{ marginBottom: '20px' }}
            key={`${errorMessages.length}-${index}`}
            type="error"
            onDismissed={() => removeError(index)}
          >
            {message}
          </Banner>
        ))}
        {successMessages.length > 0 && (
          <MultiMessageBanner
            sx={{ marginBottom: '20px' }}
            type="success"
            title={'Aðgerð tókst. Eftirfarandi aðilum hefur verið bætt á listann: '}
            messages={successMessages}
            onDismissed={() => setSuccessMessages([])}
          />
        )}
        {infoMessages.map((message, index) => (
          <Banner
            sx={{ marginBottom: '20px' }}
            key={`${errorMessages.length}-${index}`}
            type="info"
            onDismissed={() => removeInfo(index)}
          >
            {message}
          </Banner>
        ))}
        <ClaimantAccordion
          claimantsData={claimantsData}
          isAllowedToSave={isAllowedToSave}
          removeClaimant={removeClaimant}
          removeOrganization={removeOrganization}
          expandedPanels={expandedPanels}
          setExpandedPanels={setExpandedPanels}
        />
      </>
    </Panel>
  );
}
