export function formatSsn(ssn?: string | null): string {
  if (!ssn) return '';
  return ssn.length === 10 ? `${ssn.substring(0, 6)}-${ssn.substring(6, 10)}` : ssn;
}

export function removeDashIfSsn(ssn: string): string {
  const regex = /\d{6}-\d{0,4}/;
  if (ssn && ssn.length < 4) {
    return ssn.toString();
  }
  return regex.test(ssn) ? ssn.replace('-', '') : ssn;
}

export function isSSN(value: string): boolean {
  const regex = /(^\d{6}-\d{4}$|^\d{10}$)/;
  return regex.test(value);
}
