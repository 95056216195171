import { Typography, SxProps, Theme } from '@mui/material';
import { Box } from '@mui/material';
import Banner from './Banner';
import BannerType from './bannerType';

interface MultiMessageBannerProps {
  type: BannerType;
  title: string;
  messages: string[];
  isDissmissble?: boolean;
  onDismissed?: () => void;
  sx?: SxProps<Theme> | undefined;
}

export default function MultiMessageBanner({
  type,
  title,
  messages,
  isDissmissble,
  onDismissed,
  sx,
}: MultiMessageBannerProps) {
  return (
    <Banner type={type} isDismissible={isDissmissble} onDismissed={onDismissed} sx={sx}>
      <Box
        sx={{
          '& p': {
            padding: 0,
            margin: 0,
          },
          '& ul': {
            margin: 0,
            paddingLeft: '30px',
          },
          '& li': {
            fontSize: '12px',
          },
        }}
      >
        <Typography variant="body2" fontSize="14px">
          {title}
        </Typography>

        <ul>
          {messages.map((message, index) => (
            <li key={index}>
              <Typography variant="body2" fontSize="14px">
                {message}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
    </Banner>
  );
}
