import { CircularProgress, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Banner from 'src/common/UI/banners/Banner';
import ToastSuccess from 'src/common/UI/toasts/ToastSuccess';
import User from 'src/users/models/User';
import theme from 'styles/theme';
import { trpcClient } from 'pages/api/trpc/_api';

const styles = {
  buttonLink: {
    color: theme.palette.primary.dark,
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: 'Brandon Text W01 Medium',
    padding: '0px 4px',
    marginTop: '-6px',
    marginLeft: '-4px',
  },
  message: {
    color: theme.palette.mediumgrey.main,
  },
};

interface SendOnboardingEmailProps {
  user: User;
}

export default function SendOnboardingEmail({ user }: SendOnboardingEmailProps): JSX.Element {
  const [isSending, setIsSending] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  async function onClickSend() {
    setIsSending(true);

    await trpcClient.users.sendOnboardingEmail
      .query(user.id)
      .then(
        () => {
          toast(<ToastSuccess message={'Skilmálar hafa verið endursendir.'} />);
          setSuccessMessage('Skilmálar endursendir');
        },
        (e) => {
          setErrorMessage(e.message);
        }
      )
      .finally(() => {
        setIsSending(false);
      });
  }

  if (user.activityState !== 'Onboarding') return <Typography variant="body2">Já</Typography>;

  return (
    <>
      <Typography variant="body2" display="inline">
        {'Nei — '}
      </Typography>

      {isSending ? (
        <CircularProgress size={16} />
      ) : successMessage !== '' ? (
        <Typography variant="body2" display="inline" sx={styles.message}>
          {successMessage}
        </Typography>
      ) : (
        <>
          <Button variant="text" onClick={onClickSend} sx={styles.buttonLink}>
            Endursenda til samþykktar
          </Button>
          {errorMessage !== '' && <Banner type="error">{errorMessage}</Banner>}
        </>
      )}
    </>
  );
}
