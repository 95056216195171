import { IconButton, CircularProgress, SxProps, Theme } from '@mui/material';

interface CircularProgressButtonProps {
  sx?: SxProps<Theme> | undefined;
}

export default function CircularProgressButton({ sx }: CircularProgressButtonProps) {
  return (
    <IconButton sx={sx}>
      <CircularProgress size={16} />
    </IconButton>
  );
}
