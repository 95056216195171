import Panel from 'src/common/UI/layouts/Panel';
import { Grid, Typography, styled } from '@mui/material';
import { ToggleSwitch } from '@motusehf/web-ui-component-library/dist';

const RowContainer = styled(Grid)(({ index }: { index: number }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '14px 25px',
  backgroundColor: index % 2 === 0 ? '#EBEEED' : '#FAFAFA',
}));

interface ProductsPanelProps {
  hasAccessToCollection: boolean;
  setHasAccessToCollection: React.Dispatch<React.SetStateAction<boolean>>;
  hasAccessToClaimCreation: boolean;
  setHasAccessToClaimCreation: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ProductsPanel({
  hasAccessToCollection,
  setHasAccessToCollection,
  hasAccessToClaimCreation,
  setHasAccessToClaimCreation,
}: ProductsPanelProps) {
  const handleToggleChange = (index: number) => (newValue: boolean) => {
    if (index === 0) {
      setHasAccessToCollection(newValue);
    } else if (index === 1) {
      setHasAccessToClaimCreation(newValue);
    }
  };

  const rows = [
    { label: 'Innheimtuþjónusta', id: '0', value: hasAccessToCollection },
    { label: 'Kröfustofnun', id: '1', value: hasAccessToClaimCreation },
  ];

  return (
    <Panel label="Vörur">
      <Grid item xs={12}>
        {rows.map((row, index) => (
          <RowContainer container index={index} key={row.id}>
            <Grid item xs={6}>
              <Typography>{row.label}</Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <ToggleSwitch value={row.value} onChange={handleToggleChange(index)} id={row.id} />
            </Grid>
          </RowContainer>
        ))}
      </Grid>
    </Panel>
  );
}
