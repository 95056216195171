import { z } from 'zod';
import { Tag } from '@motusehf/web-ui-component-library/dist';

const ActivityStateValues = ['Onboarding', 'Active', 'Closed'] as const;
export const ActivityStateSchema = z.enum(ActivityStateValues);
export type ActivityState = z.infer<typeof ActivityStateSchema>;

const activeSx = { bgcolor: '#E3EFE1', mb: 1, ml: 2 };
const onboardingSx = { bgcolor: 'secondary', mb: 1, ml: 2 };
const ClosedSx = { bgcolor: '#EBCBCB', mb: 1, ml: 2 };

export function GetSxForState(userState: ActivityState) {
  if (userState === 'Onboarding') {
    return onboardingSx;
  }
  if (userState === 'Closed') {
    return ClosedSx;
  }
  return activeSx;
}

export function GetTextForState(userState: ActivityState) {
  if (userState === 'Onboarding') {
    return 'Óvirkur';
  }
  if (userState === 'Closed') {
    return 'Aðgangi lokað';
  }
  return 'Virkur';
}

export function GetIconForState(userState: ActivityState) {
  if (userState === 'Onboarding') {
    return (
      <div>
        <Tag label="Óvirkur" color="#EEEEEE" />
      </div>
    );
  }
  if (userState === 'Closed') {
    return (
      <div>
        <Tag label="Lokað" color="#DDB3BA" />
      </div>
    );
  }
  return (
    <div>
      <Tag label="Virkur" color="#D5E7D2" />
    </div>
  );
}
