import { Checkbox, Table, TableContainer, Typography } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { formatSsn } from 'src/common/utils/ssnUtil';
import { OrganizationSuggestion } from 'src/organizations/models/OrganizationSearchSuggestion';
import LoadingSpinner from 'src/common/UI/loading/LoadingSpinner';
import Banner from 'src/common/UI/banners/Banner';
import { api } from 'pages/api/trpc/_api';
import { Organization } from 'src/organizations/models/Organization';
import { OrganizationPartner } from 'src/organizations/models/OrganizationPartner';
import { noCacheAndNoRefetchOptions } from 'src/common/utils/noCacheAndNoRefetchOptions';
import { ClaimTemplate } from 'src/organizations/models/ClaimTemplate';
import { Tag } from '@motusehf/web-ui-component-library/dist';
import Panel from 'src/common/UI/layouts/Panel';
import usePermissions from 'src/common/hooks/authenticationHook';

interface PartnerUserAccessPanelProps {
  userId?: string;
  organization: OrganizationSuggestion | Organization;
  organizationPartners?: OrganizationPartner[];
  organizationId?: string;
  claimTemplates: string[];
  handleCheckboxChange: (partnerId: string, claimTemplate: string, isChecked: boolean) => void;
}

export default function PartnerUserAccessPanel({
  organization,
  organizationPartners,
  handleCheckboxChange,
}: PartnerUserAccessPanelProps) {
  const [orgClaimTemplates, setOrgClaimTemplates] = useState<ClaimTemplate[]>([]);
  const [selectedClaimTemplates, setSelectedClaimTemplates] = useState<{ [partnerId: string]: string[] }>({});
  const claimTemplateInitialData = api.organizations.getOrganizationClaimTemplates.useQuery(
    organization?.id,
    noCacheAndNoRefetchOptions
  );
  const { hasAdminAccess } = usePermissions();

  const sortedOrgClaimTemplates = useMemo(() => {
    return [...orgClaimTemplates].sort((a, b) => (b.isActive === true ? 1 : 0) - (a.isActive === true ? 1 : 0));
  }, [orgClaimTemplates]);

  useEffect(() => {
    if (claimTemplateInitialData.data) {
      setOrgClaimTemplates(claimTemplateInitialData.data);
    }
  }, [claimTemplateInitialData.data]);

  useEffect(() => {
    if (organizationPartners) {
      const initialSelectedTemplates: { [partnerId: string]: string[] } = {};
      organizationPartners.forEach((partner) => {
        initialSelectedTemplates[partner.id] = partner.claimTemplates;
      });
      setSelectedClaimTemplates(initialSelectedTemplates);
    }
  }, [organizationPartners]);

  if (claimTemplateInitialData.isLoading) {
    return <LoadingSpinner></LoadingSpinner>;
  } else if (claimTemplateInitialData.error) {
    return <Banner type="error">{claimTemplateInitialData.error.message}</Banner>;
  }
  return (
    <Panel label="Aðgangur samstarfsaðila">
      {orgClaimTemplates?.length == 0 ? (
        <Banner type="info" isDismissible={false}>
          Þessi eigandi er ekki með nein sniðmát til að veita aðgang að
        </Banner>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 600, opacity: hasAdminAccess ? 1 : 0.5 }} aria-label="organization table" size="small">
            <tbody>
              {sortedOrgClaimTemplates.map((claimTemplate, index) => (
                <>
                  {index === 0 && (
                    <tr style={{ backgroundColor: '#EBEEED' }}>
                      <td style={{ padding: '12px 32px' }}></td>
                      <td style={{ padding: '12px 32px' }}></td>
                      {organizationPartners?.map((partner) => (
                        <td style={{ padding: '12px 40px' }} key={partner.id}>
                          {partner.name}
                        </td>
                      ))}
                    </tr>
                  )}
                  <tr
                    style={{
                      backgroundColor: index % 2 === 0 ? '#FAFAFA' : '#EBEEED',
                    }}
                    key={claimTemplate.id + '-claimTemplate'}
                  >
                    <td style={{ padding: '12px 32px' }}>
                      {claimTemplate.isActive ? (
                        <Tag label="Virkur" color="#D5E7D2" />
                      ) : (
                        <Tag label="Óvirkur" color="#EEEEEE" />
                      )}
                    </td>
                    <td style={{ padding: '12px 32px' }}>
                      <Typography display="inline" sx={{ color: '#333333', fontWeight: '400' }}>
                        {claimTemplate.name + ` - (${formatSsn(claimTemplate.claimantSsn)})`}
                      </Typography>
                    </td>
                    {organizationPartners?.map((partner) => (
                      <td style={{ padding: '12px 32px' }} key={partner.id}>
                        <Checkbox
                          onChange={(event) => handleCheckboxChange(partner.id, claimTemplate.id, event.target.checked)}
                          defaultChecked={selectedClaimTemplates[partner.id]?.includes(claimTemplate.id) || false}
                          disabled={!hasAdminAccess}
                        />
                      </td>
                    ))}
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )}
    </Panel>
  );
}
