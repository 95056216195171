import { Checkbox, SxProps, Theme, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import { EmptyFunction } from 'src/common/utils/emptyFunction';

interface CheckboxTextRightProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
  isDisabled?: boolean;
  text: string;
  sx?: SxProps<Theme> | undefined;
}

export default function CheckboxTextRight({
  isDisabled = false,
  isChecked,
  onChange = EmptyFunction,
  text,
  sx,
}: CheckboxTextRightProps) {
  return (
    <>
      <Checkbox
        checked={isChecked}
        onChange={onChange}
        sx={sx}
        disabled={isDisabled}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Typography variant="body2" display={'inline'}>
        {text}
      </Typography>
    </>
  );
}
