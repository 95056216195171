import { Autocomplete, CircularProgress, ListItem, Paper, PaperProps, Popper, PopperProps } from '@mui/material';
import TextInput from './TextInput';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { ReactNode } from 'react';
import { SearchInputOption } from './SearchInput';

interface AutoCompleteProps<T> {
  suggestions: T[];
  inputValue: string;
  value: T | null;
  isLoading: boolean;
  label: string;
  placeholder?: string;
  hasAutoSelect: boolean;
  isDisabled?: boolean;
  ignoreEventsOnTarget?: string;
  handleSetSuggestions: (suggestions: T[]) => void;
  handleSetValue: (value: T | null) => void;
  handleSetInputValue: (inputValue: string) => void;
  renderSuggestion: (option: T) => ReactNode;
}

export default function SearchAutoComplete<T extends SearchInputOption>({
  suggestions,
  inputValue,
  value,
  isLoading,
  label,
  placeholder,
  hasAutoSelect,
  isDisabled,
  ignoreEventsOnTarget,
  handleSetSuggestions,
  handleSetValue,
  handleSetInputValue,
  renderSuggestion,
}: AutoCompleteProps<T>) {
  const CustomPaper = function (props: PaperProps) {
    return (
      <Paper
        {...props}
        sx={{
          borderRadius: 0,
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
          margin: 0,
          padding: 0,
        }}
      />
    );
  };

  const CustomPopper = function (props: PopperProps) {
    if (suggestions.length == 0) {
      return <></>;
    }

    return (
      <Popper
        {...props}
        sx={{
          '& .MuiAutocomplete-listbox': {
            padding: 0,
          },
          margin: 0,
          padding: 0,
        }}
        placement="bottom"
      />
    );
  };

  function Icon() {
    if (inputValue == value?.getLabel() && inputValue) {
      return <></>;
    }
    if (isLoading) {
      return <CircularProgress size="1rem" />;
    }
    return <SearchIcon fontSize="small" sx={{ color: 'darkgrey.main' }}></SearchIcon>;
  }
  return (
    <Autocomplete
      sx={{
        width: '100%',
        '& .MuiOutlinedInput-root': {
          '& .MuiButtonBase-root': {
            transform: 'rotate(0)',
          },
          '& .MuiAutocomplete-endAdornment': {
            '& .css-1e92n36-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator': {
              visibility: 'visible',
            },
          },
        },
      }}
      disabled={isDisabled}
      autoSelect={hasAutoSelect}
      autoHighlight
      handleHomeEndKeys
      selectOnFocus
      PaperComponent={CustomPaper}
      PopperComponent={CustomPopper}
      getOptionLabel={(option: T): string => {
        return (option as T).getLabel() ?? '';
      }}
      filterOptions={(x) => x}
      options={suggestions}
      isOptionEqualToValue={(option, value) => {
        return option.compare(value);
      }}
      value={value}
      clearIcon={<ClearIcon fontSize="small" sx={{ color: 'darkgrey.main' }}></ClearIcon>}
      popupIcon={<Icon></Icon>}
      onChange={(_event, newValue: T | null) => {
        if (ignoreEventsOnTarget && (_event.target as Element)?.classList?.contains(ignoreEventsOnTarget)) {
          return;
        }
        handleSetSuggestions(newValue ? [newValue, ...suggestions] : suggestions);
        handleSetValue(newValue);
      }}
      onInputChange={(_event, newInputValue) => {
        handleSetInputValue(newInputValue);
      }}
      renderInput={(params) => <TextInput {...params} size="small" label={label} placeholder={placeholder} />}
      renderOption={(props, option) => {
        return (
          <ListItem
            {...props}
            key={props.id}
            sx={{
              '&:hover': {
                background: '#37504633',
                '& .MuiTypography-root': {
                  fontWeight: 800,
                },
              },
            }}
          >
            {renderSuggestion(option)}
          </ListItem>
        );
      }}
    />
  );
}
