import { styled, TextField, TextFieldProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface ThemeInterface {
  theme: Theme;
}

type TextInputProps = TextFieldProps & {
  maxLength?: number;
};

export default styled(({ maxLength, ...props }: TextInputProps) => (
  <TextField size={'small'} inputProps={maxLength ? { maxLength } : undefined} {...props} />
))(({ theme }: ThemeInterface) => ({
  backgroundColor: 'white',
  width: '100%',
  '& label': {
    color: theme.palette.basicblack.main,
    fontFamily: 'Brandon Text W01 Regular',
    fontWeight: 'normal',
  },
  '& .MuiOutlinedInput-root': {
    height: '42px',
    '& fieldset': {
      borderRadius: '4px',
      borderColor: theme.palette.mediumgrey.main,
    },
    '& .MuiInputBase-input': {
      color: theme.palette.basicblack.main,
      fontFamily: 'Brandon Text W01 Regular',
      fontWeight: 'normal',
      fontSize: '14px',
    },
  },
}));
