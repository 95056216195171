import * as React from 'react';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Box, IconButton, Theme, Tooltip, Button } from '@mui/material';
import CustomButton from 'src/common/UI/forms/CustomButton';
import StatusIcon from 'src/common/UI/dataDisplay/StatusIcon';
import UserSearch from 'src/users/models/UserSearchResult';
import UserCount from './UserCount';
import { formatSsn } from 'src/common/utils/ssnUtil';
import { Tag } from '@motusehf/web-ui-component-library/dist';

interface TableProps {
  users: UserSearch[];
  handleUserInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectUser: (userId: string, userType: string) => void;
  onFetchMoreUsers: () => void;
  isLoadingMoreUsers: boolean;
  shouldShowFetchMore: boolean;
  userCount: number;
}

const TableCellStyled = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  ...theme.typography.body1,
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  pb: 1,
  pt: 1,
}));
export default function UserTable(props: TableProps): JSX.Element {
  return (
    <>
      <TableContainer component={Paper} sx={{ padding: '0px 24px' }}>
        <Table aria-label="user table" size="small">
          <TableHead>
            <TableRow>
              <TableCellStyled align="left" sx={{ width: '10%' }}>
                <Typography variant="body1">Kennitala</Typography>
              </TableCellStyled>
              <TableCellStyled align="left" sx={{ width: '57%' }}>
                <TextField
                  sx={{ width: '80%' }}
                  id="outlined-basic"
                  label="Leit"
                  variant="outlined"
                  size="small"
                  onChange={props.handleUserInputChange}
                />
              </TableCellStyled>
              <TableCellStyled align="left" sx={{ width: '25%' }}>
                <Typography variant="body1">Eigandi</Typography>
              </TableCellStyled>
              <TableCellStyled sx={{ width: '8%' }}>
                <FormControl variant="standard" sx={{ mb: 2 }}>
                  <InputLabel>
                    <Typography variant="body1">Staða</Typography>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="staða"
                    defaultValue=""
                    disableUnderline
                    IconComponent={() => null} // Þetta tekur út dropdown arrow
                    disabled // Disablar dropdown
                  >
                    <MenuItem value="">Allir</MenuItem>
                    <MenuItem value={10}>Virkur</MenuItem>
                    <MenuItem value={20}>Óvirkur</MenuItem>
                  </Select>
                </FormControl>
              </TableCellStyled>
              <TableCellStyled sx={{ width: '10%', textAlign: 'center' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.users.map((row) => (
              <TableRow key={row.id}>
                <TableCellStyled align="left">
                  <Button
                    onClick={() => props.selectUser(row.id ?? '', row.userType)}
                    style={{ textTransform: 'none', padding: 0, minWidth: 0 }}
                  >
                    <Typography variant="body2" display="inline">
                      {formatSsn(row.ssn)}
                    </Typography>
                  </Button>
                </TableCellStyled>
                <TableCellStyled align="left" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '300px' }}>
                  <Button
                    onClick={() => props.selectUser(row.id ?? '', row.userType)}
                    style={{ textTransform: 'none', padding: 0, minWidth: 0 }}
                  >
                    <Typography variant="body1" display="inline">
                      {row.name}
                    </Typography>
                  </Button>
                  <Typography variant="body2" display="inline">
                    &nbsp;&nbsp;∙&nbsp;&nbsp;{row.email ? row.email.split(';')[0] : 'ekkert netfang'}
                  </Typography>
                </TableCellStyled>
                <TableCellStyled align="left">
                  <Typography variant="body1">{row.organizationName}</Typography>
                </TableCellStyled>
                <TableCellStyled align="right">
                  <Box display="flex" justifyContent="space-between" width="115px">
                    <StatusIcon activityState={row.activityState} />
                    {row.userType === 'Partner' && (
                      <Tag color="#666666" icon={<SmartToyIcon />} iconSize={0.6} height={26} />
                    )}
                  </Box>
                </TableCellStyled>
                <TableCellStyled align="center" sx={{ textAlign: 'center', padding: 0 }}>
                  <Tooltip title="Opna yfirlit">
                    <IconButton onClick={() => props.selectUser(row.id ?? '', row.userType)}>
                      <ArrowCircleRightOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </TableCellStyled>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {props.shouldShowFetchMore ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 110,
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: '17px',
                bottom: '42px',
              }}
            >
              <UserCount userCount={props.userCount} />
            </Box>

            <CustomButton isLoading={props.isLoadingMoreUsers} onClick={props.onFetchMoreUsers}>
              SÆKJA FLEIRI
            </CustomButton>
          </Box>
        ) : null}
      </TableContainer>
    </>
  );
}
