import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid } from '@mui/material';
import LoadingSpinner from 'src/common/UI/loading/LoadingSpinner';
import Panel from 'src/common/UI/layouts/Panel';
import { useEffect, useState } from 'react';
import Banner from 'src/common/UI/banners/Banner';
import { toast } from 'react-toastify';
import ToastSuccess from 'src/common/UI/toasts/ToastSuccess';
import usePermissions from 'src/common/hooks/authenticationHook';
import _ from 'lodash';
import { ModalCancelControl } from '../../../../../common/UI/layouts/ModalCancelControl';
import { trpcClient, api } from 'pages/api/trpc/_api';
import { noCacheAndNoRefetchOptions } from 'src/common/utils/noCacheAndNoRefetchOptions';
import User from 'src/users/models/User';
import PartnerUserRoles from './PartnerUserRoles';
import { UpdateUserRequest } from 'src/users/models/UpdateUserRequest';
import { ActivityState } from 'src/common/models/ActivityState';

const styles = {
  confirmButton: {
    backgroundColor: '#375046',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '8px',
    padding: '10px 22px 11px 25px',
  },
  cancelButton: {
    color: '#666666',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '7px',
    border: '1px solid #666666',
    padding: '10px 16px',
  },
};

interface Props {
  children?: React.ReactNode;
  userId: string;
  onSuccess: (updatedUser: User) => void;
  onFailure: () => void;
  onSubmit: () => void;
  modalCancelControl: ModalCancelControl;
}

const initialState: UpdateUserRequest = {
  id: '',
  userName: '',
  name: '',
  email: '',
  ssn: '',
  phoneNumber: '',
  activityState: 'Onboarding' as ActivityState,
  userType: 'Partner',
  roles: [],
  claimants: [],
  claimTemplates: [],
  partner: { id: '', name: '', description: '' },
  partnerId: '',
};

export default function EditPartnerUserForm({ userId, onSuccess, onFailure, onSubmit, modalCancelControl }: Props) {
  const [userToEdit, setUserToEdit] = useState<UpdateUserRequest>(initialState);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [uneditedUser, setUneditedUser] = useState(userToEdit);
  const { isAllowedToSave } = usePermissions();
  const userState = api.users.getUser.useQuery(userId, noCacheAndNoRefetchOptions);

  useEffect(() => {
    if (userState.data) {
      const tempUserDto: UpdateUserRequest = {
        userName: userState.data.userName,
        name: userState.data.name,
        email: userState.data.email,
        ssn: userState.data.ssn,
        phoneNumber: userState.data.phoneNumber,
        activityState: userState.data.activityState,
        userType: userState.data.userType,
        claimants: userState.data.claimants,
        roles: userState.data.roles,
        claimTemplates: userState.data.claimTemplates,
        partnerId: userState.data.partnerId,
        partner: userState.data.partner,
        id: userState.data.id,
      };
      setUneditedUser(tempUserDto);
      setUserToEdit(tempUserDto);
    }
  }, [userState.data]);
  const forceCancelEdit = () => {
    modalCancelControl.isSafe = true;
    modalCancelControl.closeFunc();
  };

  function safeEditUser(editUserFunc: (prevState: UpdateUserRequest) => UpdateUserRequest) {
    setUserToEdit(editUserFunc);
  }
  function hasUserBeenModified() {
    const hasBeenModified = !_.isEmpty(_.xor(userToEdit.roles, uneditedUser.roles));
    if (hasBeenModified) {
      modalCancelControl.isSafe = false;
    } else {
      modalCancelControl.isSafe = true;
    }

    return hasBeenModified;
  }

  const canSave = userToEdit && !isSaving && isAllowedToSave && hasUserBeenModified();

  const handleRoleChange = React.useCallback((value: string) => {
    if (!value || value === '') {
      safeEditUser((prevState: UpdateUserRequest) => ({
        ...prevState,
        roles: prevState.roles.filter((role: string) => !role.startsWith('Claim')),
      }));
    } else {
      safeEditUser((prevState: UpdateUserRequest) => ({
        ...prevState,
        roles: [...prevState.roles.filter((role: string) => !role.startsWith('Claim')), value],
      }));
    }
  }, []);

  if (userState.isLoading || userToEdit === initialState) {
    return <LoadingSpinner></LoadingSpinner>;
  } else if (userState.isError || !userState.data) {
    return <Banner type="error">{userState.error?.message}</Banner>;
  }

  const onSaveChanges = () => {
    if (!canSave) return;
    setIsSaving(true);
    onSubmit();
    const changedUser: UpdateUserRequest = {
      userName: userToEdit.userName,
      name: userToEdit.name,
      email: userToEdit.email,
      ssn: userToEdit.ssn,
      phoneNumber: userToEdit.phoneNumber,
      activityState: userToEdit.activityState,
      userType: userToEdit.userType,
      claimants: userToEdit.claimants,
      claimTemplates: userToEdit.claimTemplates,
      roles: userToEdit.roles,
      partnerId: userToEdit.partnerId,
      partner: userToEdit.partner,
      id: userToEdit.id,
    };
    trpcClient.users.updateUser
      .mutate(changedUser)
      .then(
        (data) => {
          toast(<ToastSuccess message={'Notandi hefur verið uppfærður.'} />);
          onSuccess(data);
        },
        (error) => {
          setErrorMessage(error.message);
          onFailure();
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  const onDismissError = () => {
    setErrorMessage('');
  };
  return (
    <>
      <Box sx={{ mb: -3 }}>
        <Typography variant="h1" display="inline" sx={{ marginRight: '12px' }}>
          {userState.data.name}
        </Typography>
      </Box>
      <Panel label="Notandi">
        <Box sx={{ mb: 3 }} />
        <Grid container rowSpacing="34px" columnSpacing="128px">
          <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
            <Typography>Nafn</Typography>
            <Typography fontWeight={100}>{userState.data.name}</Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
            <Typography>Samstarfsaðili</Typography>
            {userState.data?.partner?.description ? (
              <Typography fontWeight={100}>{userState.data.partner.description}</Typography>
            ) : (
              <Typography fontWeight={100}>Ekki tókst að sækja samstarfsaðila</Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Netfang</Typography>
            <Typography fontWeight={100}>{userState.data.email}</Typography>
          </Grid>
        </Grid>
      </Panel>

      <PartnerUserRoles userId={userState.data.id} handleRoleChange={handleRoleChange} />

      <Panel label="">
        {errorMessage && (
          <Grid container justifyContent="flex-end" spacing={2} sx={{ mb: 2 }}>
            <Banner type="error" onDismissed={onDismissError}>
              {errorMessage}
            </Banner>
          </Grid>
        )}
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" sx={styles.cancelButton} onClick={forceCancelEdit}>
              Hætta við
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" disabled={!canSave} sx={styles.confirmButton} onClick={onSaveChanges}>
              Vista
            </Button>
          </Grid>
        </Grid>
      </Panel>
    </>
  );
}
