import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid } from '@mui/material';
import Panel from 'src/common/UI/layouts/Panel';
import { useState } from 'react';
import { CreatePartnersRequest } from 'src/partners/models/CreatePartnersRequest';
import TextInput from 'src/common/UI/forms/TextInput';
import { trpcClient } from 'pages/api/trpc/_api';
import { toast } from 'react-toastify';
import ToastSuccess from 'src/common/UI/toasts/ToastSuccess';
import Banner from 'src/common/UI/banners/Banner';
import usePermissions from 'src/common/hooks/authenticationHook';

const styles = {
  confirmButton: {
    backgroundColor: '#375046',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '8px',
    padding: '10px 22px 11px 25px',
  },
  cancelButton: {
    color: '#666666',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '7px',
    border: '1px solid #666666',
    padding: '10px 16px',
  },
  foreignLink: {
    color: '#73AF69',
    fontWeight: 400,
    textDecoration: 'none',
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
};

interface Props {
  children?: React.ReactNode;
  onSuccess: () => void;
  onFailure: () => void;
  onCancel: () => void;
  onSubmit: () => void;
}

export default function CreateUserForm({ onSuccess, onFailure, onCancel, onSubmit }: Props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const { isAllowedToSave } = usePermissions();
  const [nameOrSsn, setNameOrSsn] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [noOrgErrorMessage] = useState('Enginn eigandi hefur veitt þessum aðila heimild að sínum kröfum.');

  const onNameOrSsnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setNameOrSsn(event.target.value);
  };

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setDescription(event.target.value);
  };

  const handleSaveChanges = (): void => {
    if (isSaving || !isAllowedToSave) return;
    setIsSaving(true);
    onSubmit();
    const req: CreatePartnersRequest = {
      name: nameOrSsn,
      description: description,
    };
    trpcClient.partners.createPartner
      .mutate(req)
      .then(
        () => {
          toast(<ToastSuccess message={'Samstarfsaðili hefur verið stofnaður.'} />);
          onSuccess();
        },
        () => {
          setErrorMessage('Hér hefur eitthvað farið úrskeiðis');
          onFailure();
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };
  const onDismissError = () => {
    setErrorMessage('');
  };

  return (
    <>
      <Box sx={{ mb: -3 }}>
        <Typography variant="h1" display="inline" sx={{ marginRight: '12px' }}>
          Stofna samstarfsaðila
        </Typography>
      </Box>
      <Panel label="Aðili">
        <Box sx={{ mb: 3 }} />
        <Grid container rowSpacing="34px" columnSpacing="128px">
          <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
            <TextInput label="Nafn eða kennitala" onChange={onNameOrSsnChange} maxLength={40} />
          </Grid>
          <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
            <TextInput label="Lýsing" onChange={onDescriptionChange} />
          </Grid>
        </Grid>
      </Panel>

      <Panel label="Tengja eiganda">
        <Box sx={{ mb: 3 }} />
        <Grid>
          <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
            <Banner type="info" isDismissible={false}>
              {noOrgErrorMessage}
            </Banner>
          </Grid>
        </Grid>
      </Panel>

      <Panel label="">
        {errorMessage && (
          <Grid container justifyContent="flex-end" spacing={2} sx={{ mb: 2 }}>
            <Banner type="error" onDismissed={onDismissError}>
              {errorMessage}
            </Banner>
          </Grid>
        )}
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" sx={styles.cancelButton} onClick={onCancel}>
              Hætta við
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={isSaving || !isAllowedToSave}
              sx={styles.confirmButton}
              onClick={handleSaveChanges}
            >
              Vista
            </Button>
          </Grid>
        </Grid>
      </Panel>
    </>
  );
}
