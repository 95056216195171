import { Box } from '@mui/material';
import Claimant from 'src/users/models/Claimant';
import { useState, useEffect } from 'react';
import groupBySSNO from 'src/users/services/claimantSort';
import { formatSsn } from 'src/common/utils/ssnUtil';
import { OrganizationSuggestion } from 'src/organizations/models/OrganizationSearchSuggestion';
import LoadingSpinner from 'src/common/UI/loading/LoadingSpinner';
import Banner from 'src/common/UI/banners/Banner';
import User from 'src/users/models/User';
import ClaimantMultiSelect from './ClaimantMultiSelect';
import { api } from 'pages/api/trpc/_api';
import { Organization } from 'src/organizations/models/Organization';
import { noCacheAndNoRefetchOptions } from 'src/common/utils/noCacheAndNoRefetchOptions';
import CustomPanel from 'src/common/UI/layouts/CustomPanel';

interface EditClaimantProps {
  user: User | null;
  organization: OrganizationSuggestion | Organization;
  claimants: string[];
  organizationId?: string;
  handleClaimantsChange: (claimants: string[]) => void;
}

export default function ClaimantsPanel({
  user = null,
  organization,
  claimants: claimantsIn,
  handleClaimantsChange,
  organizationId,
}: EditClaimantProps) {
  const [orgClaimants, setOrgClaimants] = useState<Claimant[]>([]);
  const [outOfOrgClaimants, setOutOfOrgClaimants] = useState<Claimant[]>([]);
  const [selectedClaimants, setSelectedClaimants] = useState<string[]>(claimantsIn);
  const [orgClaimantsSorted, setOrgClaimantsSorted] = useState<{ [ssno: string]: Claimant[] }>({});
  const [outOfOrgClaimantsSorted, setOutOfOrgClaimantsSorted] = useState<{ [ssno: string]: Claimant[] }>({});
  const claimantInitialData = api.claimants.getUserAndOrgClaimants.useQuery(
    {
      organizationId: organization?.id || null,
      userId: user?.id || null,
    },
    noCacheAndNoRefetchOptions
  );
  const organizationState = api.organizations.getOrganization.useQuery(organizationId || '', {
    ...noCacheAndNoRefetchOptions,
    enabled: !!organizationId,
  });
  const userState = user?.id ? api.users.getUser.useQuery(user?.id, noCacheAndNoRefetchOptions) : undefined;
  const hasUserCollectionAccess = userState?.data?.organization?.hasAccessToCollection;

  let hasOrganizationAccess = false;
  if ('hasAccessToClaimCreation' in organization) {
    hasOrganizationAccess = organization.hasAccessToCollection;
  } else {
    hasOrganizationAccess = organizationState?.data?.hasAccessToCollection || false;
  }
  const hasAccess = hasUserCollectionAccess ?? hasOrganizationAccess;

  useEffect(() => {
    if (claimantInitialData.data) {
      setOrgClaimants(claimantInitialData.data.organizationClaimants);
      setOrgClaimantsSorted(groupBySSNO(claimantInitialData.data.organizationClaimants));
      const userClaimantsOutsideOfOrg = claimantInitialData.data.userClaimants.filter(
        (x) => claimantInitialData.data.organizationClaimants.findIndex((c) => c.number === x.number) < 0
      );
      setOutOfOrgClaimants(userClaimantsOutsideOfOrg);
      setOutOfOrgClaimantsSorted(groupBySSNO(userClaimantsOutsideOfOrg));
    }
  }, [claimantInitialData.data]);

  useEffect(() => {
    setOrgClaimantsSorted(groupBySSNO(orgClaimants));
    setOutOfOrgClaimantsSorted(groupBySSNO(outOfOrgClaimants));
  }, [orgClaimants, outOfOrgClaimants]);

  useEffect(() => {
    handleClaimantsChange(selectedClaimants);
  }, [handleClaimantsChange, selectedClaimants]);

  const organizationTitle = organization ? `Tengsl við ${organization.name} (kt: ${formatSsn(organization.ssn)}):` : '';

  if (claimantInitialData.isLoading) {
    return <LoadingSpinner></LoadingSpinner>;
  } else if (claimantInitialData.error) {
    return <Banner type="error">{claimantInitialData.error.message}</Banner>;
  }

  function getTotalSelectedOfClaimant(claimants: Claimant[]) {
    if (!claimants) return 0;
    let totalSelected = 0;
    for (let i = 0; i < claimants.length; i++) {
      if (selectedClaimants?.includes(claimants[i].number)) {
        totalSelected++;
      }
    }
    return totalSelected;
  }

  function areAllClaimantsSelected(claimants: Claimant[]) {
    const totalSelected = getTotalSelectedOfClaimant(claimants);
    return totalSelected === claimants.length;
  }

  const toggleClaimantSelection = (claimantNumber: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedClaimants((prevState: string[]) => {
        return prevState.filter((claimant) => claimant !== claimantNumber);
      });
    } else {
      setSelectedClaimants((prevState: string[]) => {
        return [...prevState, claimantNumber];
      });
    }
  };

  function checkOrUncheckAllClaimants(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, claimants: Claimant[]) {
    e.stopPropagation();
    const isAllSelected = areAllClaimantsSelected(claimants);
    const claimantNumbers = claimants.map((x) => x.number);

    if (!isAllSelected) {
      const claimantsToAdd = claimantNumbers.filter((x) => !selectedClaimants.includes(x));
      setSelectedClaimants((prevState: string[]) => {
        return [...prevState, ...claimantsToAdd];
      });
    } else {
      const claimantsToRemove = claimantNumbers.filter((x) => selectedClaimants.includes(x));
      const newSelectedClaimants = selectedClaimants.filter((item) => !claimantsToRemove.includes(item));
      setSelectedClaimants(newSelectedClaimants);
    }
  }
  return (
    <CustomPanel label={'Kröfuhafar'} borderColor="transparent">
      <ClaimantMultiSelect
        hasAccess={hasAccess}
        title={organizationTitle}
        claimantsSorted={orgClaimantsSorted}
        selectedClaimants={selectedClaimants}
        checkClaimant={toggleClaimantSelection}
        checkOrUncheckAllClaimants={checkOrUncheckAllClaimants}
        areAllClaimantsSelected={areAllClaimantsSelected}
      />
      {outOfOrgClaimantsSorted && (
        <Box sx={{ mt: 4 }}>
          <ClaimantMultiSelect
            hasAccess={hasAccess}
            title={'Aðrir aðgangar'}
            claimantsSorted={outOfOrgClaimantsSorted}
            selectedClaimants={selectedClaimants}
            checkClaimant={toggleClaimantSelection}
            checkOrUncheckAllClaimants={checkOrUncheckAllClaimants}
            areAllClaimantsSelected={areAllClaimantsSelected}
          />
        </Box>
      )}
    </CustomPanel>
  );
}
