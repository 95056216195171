import * as React from 'react';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material';

interface UserCount {
  userCount: number;
  sx?: SxProps<Theme>;
}

export default function HeaderPage(Props: UserCount): JSX.Element {
  return (
    <Typography variant="body2" sx={{}}>
      Fjöldi notenda {Props.userCount >= 1000 ? '1000+' : Props.userCount}
    </Typography>
  );
}
