import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import theme from 'styles/theme';

const style = {
  outerBox: {
    position: 'absolute' as const,
    top: '5%',
    left: '0.5%',
    width: '99%',
    height: '100%',
  },
  box: {
    position: 'absolute' as const,
    top: '5%',
    left: '0.5%',
    width: '99%',
    height: '89%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px 5px 0px 0px',
    p: 10,
    overflow: 'scroll',
  },
  closeIcon: {
    position: 'absolute' as const,
    top: '5%',
    zIndex: 1500,
    left: '50%',
    backgroundColor: 'white',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible !important',
  },
};

interface Props {
  children?: React.ReactNode;
  isOpen: boolean;
  handleClose: () => void;
}

export default function FullscreenModal({ children, isOpen, handleClose }: Props) {
  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500, style: { backgroundColor: theme.palette.primary.main, opacity: '0.5' } }}
      >
        <Fade in={isOpen}>
          <Box sx={style.outerBox}>
            <IconButton aria-label="close" size="medium" disableRipple onClick={handleClose} sx={style.closeIcon}>
              <CloseRoundedIcon fontSize="inherit" color="primary" />
            </IconButton>
            <Box sx={style.box}>{children}</Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
