import { IconButton, SxProps, Theme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { EmptyFunction } from 'src/common/utils/emptyFunction';

interface DeleteButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isDisabled?: boolean;
  sx?: SxProps<Theme> | undefined;
}

export default function DeleteButton({ isDisabled = false, onClick = EmptyFunction, sx }: DeleteButtonProps) {
  return (
    <IconButton aria-label="delete" sx={sx} disabled={isDisabled} onClick={onClick}>
      <DeleteIcon />
    </IconButton>
  );
}
