import { z } from 'zod';
import { createEnv } from '@t3-oss/env-nextjs';

export const env = createEnv({
  server: {
    NODE_ENV: z.enum(['development', 'production','staging','sandbox', 'local']),
    API_URL: z.string(),
    AUTHORIZATION_SERVICE_URL: z.string(),
    NEXTAUTH_URL: z.string(),
    STS_CLIENT: z.string(),
    KEY_VAULT_URL: z.string(),
  },
  client: {
    DATADOG_APPLICATION_ID: z.string(),
    DATADOG_CLIENT_TOKEN: z.string(),
    VIDSKIPTAVEFUR_URL: z.string(),
  },
  shared: {
    ENV: z.enum(['development', 'production','staging','sandbox']),
    STS_URL: z.string(),
  },
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    ENV: process.env.NEXT_PUBLIC_ENV,
    API_URL: process.env.API_URL,
    STS_URL: process.env.NEXT_PUBLIC_STS_URL,
    AUTHORIZATION_SERVICE_URL: process.env.AUTHORIZATION_SERVICE_URL,
    NEXTAUTH_URL: process.env.NEXTAUTH_URL,
    STS_CLIENT: process.env.STS_CLIENT,
    KEY_VAULT_URL: process.env.KEY_VAULT_URL,
    DATADOG_APPLICATION_ID: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    VIDSKIPTAVEFUR_URL: process.env.NEXT_PUBLIC_VIDSKIPTAVEFUR_URL,
  },
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
});
