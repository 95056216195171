import { Box, Grid } from '@mui/material';
import { toDate } from 'src/common/utils/dateUtil';
import Panel from 'src/common/UI/layouts/Panel';
import User from 'src/users/models/User';
import UserInfo from './UserInfo';
import { formatSsn } from 'src/common/utils/ssnUtil';
import SendOnboardingEmail from './SendOnboardingEmail';
import isEmail from 'validator/lib/isEmail';

import SearchInput from 'src/common/UI/forms/SearchInput';
import NationalRegistryEntry from 'src/common/models/NationalRegistryEntry';
import NationalRegistryService from 'src/common/services/nationalRegistryService';
import EditableTextInput from 'src/common/UI/forms/EditableTextInput';
import { Organization } from 'src/organizations/models/Organization';

interface UserInfoPanelProps {
  user: User;
  organization: Organization;
  isForeign?: boolean;
  onIsForeignChange?: () => void;
  onForeignNameChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  setNationalRegistryEntry?: (value: NationalRegistryEntry | null, suggestions: NationalRegistryEntry[] | null) => void;
  handleUpdatedEmail: (value: string | null) => void;
}

export default function UserInfoPanel({
  user,
  organization,
  handleUpdatedEmail,
  setNationalRegistryEntry = () => void 0,
}: UserInfoPanelProps) {
  const nationalRegistryService = new NationalRegistryService();

  return (
    <Panel label="Notandi">
      <Grid container columns={{ xs: 2 }} rowSpacing="32px" columnSpacing="128px">
        <Grid item xs={1}>
          <UserInfo title={'Aðgangur stofnaður'} info={toDate(user.registrationDate)} />
        </Grid>
        <Grid item xs={1}>
          <UserInfo title={'Síðast innskráður'} info={user.lastSignInDate ? toDate(user.lastSignInDate) : 'Aldrei'} />
        </Grid>
        <Grid item xs={1}>
          <UserInfo title={'Hefur samþykkt skilmála'} info={<SendOnboardingEmail user={user} />} />
        </Grid>
        <Grid item xs={1}>
          <UserInfo title={'Notandanafn'} info={user.userName ?? ''} />
        </Grid>
        <Grid item xs={1}>
          <UserInfo
            title={'Lögaðili sem aðgangur tilheyrir'}
            info={`${organization?.name}. (kt. ${formatSsn(organization?.ssn)})`}
          />
        </Grid>
        <Grid item xs={1}>
          <UserInfo
            title={'Netfang notanda'}
            info={
              <EditableTextInput
                value={user.email}
                updateValue={handleUpdatedEmail}
                validator={isEmail}
                errorText="Þetta er ekki gilt netfang"
              />
            }
          />
        </Grid>
        {!user.ssn && (
          <Grid item xs={1}>
            <UserInfo
              title={'Nafn eða kennitala notanda'}
              info={
                <Box sx={{ mt: 1 }}>
                  <SearchInput<NationalRegistryEntry>
                    searchSuggestionService={nationalRegistryService}
                    onValue={setNationalRegistryEntry}
                    label=""
                    placeholder="Nafn eða kennitala notanda (einstaklings)"
                  />
                </Box>
              }
            />
          </Grid>
        )}
      </Grid>
    </Panel>
  );
}
