import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Button, Grid, Tab } from '@mui/material';
import LoadingSpinner from 'src/common/UI/loading/LoadingSpinner';
import UserInfoPanel from './userInfoPanel/UserInfoPanel';
import AccessPrivilegesPanel from '../AccessPrivilegesPanel';
import ClaimantsPanel from '../ClaimantsPanel';
import OrgClaimantsPanel from 'src/organizations/UI/upsertOrganization/OrganizationClaimantsPanel';
import Panel from 'src/common/UI/layouts/Panel';
import { useEffect, useState } from 'react';
import { formatSsn } from 'src/common/utils/ssnUtil';
import Banner from 'src/common/UI/banners/Banner';
import { toast } from 'react-toastify';
import ToastSuccess from 'src/common/UI/toasts/ToastSuccess';
import usePermissions from 'src/common/hooks/authenticationHook';
import { ActivityState } from 'src/common/models/ActivityState';
import ActivationButton from 'src/common/UI/forms/ActivationButton';
import _ from 'lodash';
import { ModalCancelControl } from '../../../../common/UI/layouts/ModalCancelControl';
import MimicUserButton from '../../components/MimicUserButton';
import NationalRegistryEntry from 'src/common/models/NationalRegistryEntry';
import { trpcClient, api } from 'pages/api/trpc/_api';
import { UserType } from 'src/users/models/UserType';
import { UpdateUserRequest } from 'src/users/models/UpdateUserRequest';
import { noCacheAndNoRefetchOptions } from 'src/common/utils/noCacheAndNoRefetchOptions';
import User from 'src/users/models/User';
import theme from 'styles/theme';
import { useRouter } from 'next/router';
import CustomPanel from 'src/common/UI/layouts/CustomPanel';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ClaimantRoles from '../ClaimantRoles';
import ClaimTemplatePanel from '../ClaimTemplatePanel';

const styles = {
  confirmButton: {
    backgroundColor: '#375046',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '8px',
    padding: '10px 22px 11px 25px',
  },
  cancelButton: {
    color: '#666666',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '7px',
    border: '1px solid #666666',
    padding: '10px 16px',
  },
};

const stylesTab = {
  tabPanel: {
    padding: '24px 0px',
    position: 'relative',
  },
  tabIndicator: {
    '& .MuiTabs-indicator': {
      width: '80px',
      background: `linear-gradient(to right, ${theme.palette.primary.dark} 46.35%, ${theme.palette.secondary.main} 80.73%, ${theme.palette.lightgrey.main} 100%)`,
      height: '4px',
    },
  },
  tabIndicatorLeft: {
    '& .MuiTabs-indicator': {
      width: '80px',
      background: `linear-gradient(to left, ${theme.palette.primary.dark} 46.35%, ${theme.palette.secondary.main} 80.73%, ${theme.palette.lightgrey.main} 100%)`,
      height: '4px',
    },
  },
  tab: {
    pl: 0,
    pr: 0,
    mr: 2,
  },
};

interface Props {
  children?: React.ReactNode;
  userId: string;
  onSuccess: (updatedUser: User) => void;
  onFailure: () => void;
  onSubmit: () => void;
  modalCancelControl: ModalCancelControl;
}

const initialState: UpdateUserRequest = {
  id: '',
  userName: '',
  name: '',
  email: '',
  ssn: '',
  phoneNumber: '',
  activityState: 'Onboarding' as ActivityState,
  userType: 'Claimant' as UserType,
  roles: [],
  claimants: [],
  claimTemplates: [],
  partnerId: null,
  partner: null,
};

export default function EditUserForm({ userId, onSuccess, onFailure, onSubmit, modalCancelControl }: Props) {
  const [userToEdit, setUserToEdit] = useState<UpdateUserRequest>(initialState);
  const [nationalRegistryEntry, setNationalRegistryEntry] = useState<NationalRegistryEntry | null>(null);
  const [updatedEmail, setUpdatedEmail] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [uneditedUser, setUneditedUser] = useState(userToEdit);
  const { isAllowedToSave } = usePermissions();
  const [selectedTab, setSelectedTab] = useState('0');
  const router = useRouter();
  const userState = api.users.getUser.useQuery(userId, noCacheAndNoRefetchOptions);

  useEffect(() => {
    const org = router.query['eigandi'];
    if (org) {
      setSelectedTab('1');
    }
  }, [router.query]);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (userState.data) {
      const tempUserDto: UpdateUserRequest = {
        id: userState.data.id,
        userName: userState.data.userName,
        name: userState.data.name,
        email: userState.data.email,
        ssn: userState.data.ssn,
        phoneNumber: userState.data.phoneNumber,
        activityState: userState.data.activityState,
        userType: userState.data.userType,
        roles: userState.data.roles,
        claimants: userState.data.claimants,
        claimTemplates: userState.data.claimTemplates,
        partnerId: null,
        partner: null,
      };
      setUneditedUser(tempUserDto);
      setUserToEdit(tempUserDto);
    }
  }, [userState.data]);
  const forceCancelEdit = () => {
    modalCancelControl.isSafe = true;
    modalCancelControl.closeFunc();
  };

  function safeEditUser(editUserFunc: (prevState: UpdateUserRequest) => UpdateUserRequest) {
    setUserToEdit(editUserFunc);
  }
  function hasUserBeenModified() {
    const hasBeenModified =
      userToEdit.activityState !== uneditedUser.activityState ||
      !_.isEmpty(_.xor(userToEdit.roles, uneditedUser.roles)) ||
      !_.isEmpty(_.xor(userToEdit.claimants, uneditedUser.claimants)) ||
      !_.isEmpty(_.xor(userToEdit.claimTemplates, uneditedUser.claimTemplates)) ||
      nationalRegistryEntry ||
      updatedEmail;
    if (hasBeenModified) {
      modalCancelControl.isSafe = false;
    } else {
      modalCancelControl.isSafe = true;
    }

    return hasBeenModified;
  }

  const onActivationChange = () => {
    safeEditUser((prevState: UpdateUserRequest) => {
      if (!isAllowedToSave) {
        return prevState;
      }
      return { ...prevState, activityState: getActivityState(userToEdit.activityState) };
    });
  };

  function getActivityState(state: ActivityState) {
    if (state === 'Closed') {
      if (userState.data.isEmailConfirmed) {
        return 'Active';
      } else {
        return 'Onboarding';
      }
    } else {
      return 'Closed';
    }
  }
  const canSave = userToEdit && !isSaving && isAllowedToSave && hasUserBeenModified();

  const onAccessPrivilegeChange = (roleIds: string[], isSelected: boolean) => {
    if (isSelected) {
      safeEditUser((prevState: UpdateUserRequest) => {
        const roles = prevState.roles;
        const newRoles = [...roles, ...roleIds];
        return { ...prevState, roles: newRoles.filter((item, index) => newRoles.indexOf(item) === index) };
      });
    } else {
      safeEditUser((prevState: UpdateUserRequest) => {
        const roles = prevState.roles;
        return {
          ...prevState,
          roles: roles.filter((x: string) => {
            return !roleIds.includes(x);
          }),
        };
      });
    }
  };

  const handleUpdatedEmail = (value: string | null) => {
    setUpdatedEmail(value === userToEdit.email ? null : value);
  };

  const handleClaimantsChange = React.useCallback((value: string[]) => {
    safeEditUser((prevState: UpdateUserRequest) => {
      return { ...prevState, claimants: value };
    });
  }, []);

  const handleClaimTemplateChange = React.useCallback((value: string[]) => {
    safeEditUser((prevState: UpdateUserRequest) => {
      return { ...prevState, claimTemplates: value };
    });
  }, []);

  const handleRoleChange = React.useCallback((value: string) => {
    if (!value || value === '') {
      safeEditUser((prevState: UpdateUserRequest) => ({
        ...prevState,
        roles: prevState.roles.filter((role: string) => !role.startsWith('Claim')),
      }));
    } else {
      safeEditUser((prevState: UpdateUserRequest) => ({
        ...prevState,
        roles: [...prevState.roles.filter((role: string) => !role.startsWith('Claim')), value],
      }));
    }
  }, []);

  if (userState.isLoading || userToEdit === initialState) {
    return <LoadingSpinner></LoadingSpinner>;
  } else if (userState.isError || !userState.data) {
    return <Banner type="error">{userState.error?.message}</Banner>;
  }

  const canGetAccessToOtherClaimants = userState.data.organization?.canGetAccessToOtherClaimants ?? false;
  const onSaveChanges = () => {
    if (!canSave) return;
    setIsSaving(true);
    onSubmit();
    const changedUser: UpdateUserRequest = {
      id: userToEdit.id,
      userName: userToEdit.userName,
      name: nationalRegistryEntry ? nationalRegistryEntry.name : userToEdit.name,
      email: updatedEmail ?? userToEdit.email,
      ssn: nationalRegistryEntry ? nationalRegistryEntry.ssn : userToEdit.ssn,
      phoneNumber: userToEdit.phoneNumber,
      activityState: userToEdit.activityState,
      userType: userToEdit.userType,
      roles: userToEdit.roles,
      claimants: userToEdit.claimants,
      claimTemplates: userToEdit.claimTemplates,
      partnerId: null,
      partner: null,
    };
    trpcClient.users.updateUser
      .mutate(changedUser)
      .then(
        (data) => {
          toast(<ToastSuccess message={'Notandi hefur verið uppfærður.'} />);
          onSuccess(data);
        },
        (error) => {
          setErrorMessage(error.message);
          onFailure();
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  const onDismissError = () => {
    setErrorMessage('');
  };

  return (
    <>
      <Grid container sx={{ mb: -3 }} justifyContent="space-between">
        <Grid item>
          <Typography variant="h1" display="inline" sx={{ marginRight: '12px' }}>
            {userState.data.name}
          </Typography>
          <Typography variant="body2" display="inline">{`kt. ${formatSsn(userState.data.ssn)}`}</Typography>
          <ActivationButton
            activityState={userToEdit.activityState}
            handleActivation={onActivationChange}
          ></ActivationButton>
        </Grid>
        <Grid item>
          <MimicUserButton userId={userState.data.id} />
        </Grid>
      </Grid>
      <UserInfoPanel
        user={userState.data}
        organization={userState.data.organization}
        setNationalRegistryEntry={setNationalRegistryEntry}
        handleUpdatedEmail={handleUpdatedEmail}
      />

      <CustomPanel>
        <TabContext value={selectedTab}>
          <div style={{ display: 'flex' }}>
            <div style={{ flexBasis: '20%' }}></div>
            <TabList
              onChange={handleChange}
              sx={selectedTab == '0' ? stylesTab.tabIndicator : stylesTab.tabIndicatorLeft}
            >
              <Tab label="Innheimta" value="0" tabIndex={0} sx={stylesTab.tab} />
              <Tab label="Kröfustofnun" value="1" tabIndex={0} sx={stylesTab.tab} />
            </TabList>
          </div>
          <div style={{ position: 'relative' }}>
            <TabPanel value="0" sx={stylesTab.tabPanel}>
              <AccessPrivilegesPanel
                userId={userState.data.id}
                hasPresetPrivileges={true}
                checkboxKeys={userToEdit.roles}
                handleCheckboxChange={onAccessPrivilegeChange}
              ></AccessPrivilegesPanel>
              {canGetAccessToOtherClaimants ? (
                <OrgClaimantsPanel
                  organizationOrUser={userState.data}
                  onClaimantsChange={handleClaimantsChange}
                  isCreatingOrganization={false}
                  canGetAccessToOtherClaimants={true}
                />
              ) : (
                <ClaimantsPanel
                  user={userState.data}
                  organization={userState.data.organization}
                  claimants={userToEdit.claimants}
                  handleClaimantsChange={handleClaimantsChange}
                />
              )}
            </TabPanel>
            <TabPanel value="1" sx={stylesTab.tabPanel}>
              <ClaimantRoles userId={userState.data.id} handleRoleChange={handleRoleChange} />
              <ClaimTemplatePanel
                userId={userState.data.id}
                organization={userState.data.organization}
                claimTemplates={userState.data.claimTemplates}
                handleClaimTemplateChange={handleClaimTemplateChange}
              />
            </TabPanel>
          </div>
        </TabContext>
      </CustomPanel>
      <Panel label="">
        {errorMessage && (
          <Grid container justifyContent="flex-end" spacing={2} sx={{ mb: 2 }}>
            <Banner type="error" onDismissed={onDismissError}>
              {errorMessage}
            </Banner>
          </Grid>
        )}
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" sx={styles.cancelButton} onClick={forceCancelEdit}>
              Hætta við
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" disabled={!canSave} sx={styles.confirmButton} onClick={onSaveChanges}>
              Vista
            </Button>
          </Grid>
        </Grid>
      </Panel>
    </>
  );
}
