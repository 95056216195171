import { TextFieldProps } from '@mui/material';
import * as React from 'react';
import isLength from 'validator/lib/isLength';
import TextInput from './TextInput';

interface InputFieldProps {
  value?: string;
  textInputProps?: TextFieldProps;
  errorText: string;
  requiredText: string;
  label: string;
  minLength: number;
  isDisabled?: boolean;
  validator: (value: string) => boolean;
  handleChange: (value: string, isValid: boolean) => void;
}

export default function EmailTextField(props: InputFieldProps): JSX.Element {
  const [value, setValue] = React.useState(props.value ?? '');
  const [isValid, setIsValid] = React.useState(true);
  const [isDirty, setIsDirty] = React.useState(false);
  const [shouldShowHelperText, setShouldShowHelperText] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const val = event.target.value;
    const isValidValue = props.validator(val) && isLength(val, { min: props.minLength });
    setIsValid(isValidValue);
    setValue(val);
    props.handleChange(val, isValidValue);
  };

  function errorMessage() {
    if (shouldShowHelperText) {
      if (value === '') {
        return props.requiredText;
      } else if (!isValid) {
        return props.errorText;
      }
    } else {
      return '';
    }
  }

  return (
    <TextInput
      disabled={props.isDisabled}
      error={isDirty && isValid === false}
      onBlur={() => [setIsDirty(true), setShouldShowHelperText(true)]}
      id={props.label}
      label={props.label}
      helperText={errorMessage()}
      value={value}
      onChange={(e) => handleChange(e)}
      {...props.textInputProps}
    />
  );
}
