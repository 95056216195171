import { IconButton, SxProps, Theme } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { EmptyFunction } from 'src/common/utils/emptyFunction';

interface ClearOutlinedButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  sx?: SxProps<Theme> | undefined;
}

export default function ClearOutlinedButton({
  isDisabled = false,
  onClick = EmptyFunction,
  sx,
}: ClearOutlinedButtonProps) {
  return (
    <IconButton sx={sx} disabled={isDisabled} onClick={onClick}>
      <ClearOutlinedIcon color="error" fontSize="small" />
    </IconButton>
  );
}
