import { Tooltip } from '@mui/material';
import { GetIconForState, GetTextForState, ActivityState } from '../../models/ActivityState';

interface StatusIconProps {
  activityState: ActivityState;
}

export default function StatusIcon({ activityState }: StatusIconProps): JSX.Element {
  return (
    <Tooltip title={GetTextForState(activityState)} placement="left" arrow tabIndex={-1}>
      {GetIconForState(activityState)}
    </Tooltip>
  );
}
