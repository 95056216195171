import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import AuthGuard from 'src/auth/authGuard';
import Layout from 'src/layout/Layout';
import OrganizationOverviewTab from 'src/organizations/UI/overview/OrganizationOverviewTab';
import UserOverviewTab from 'src/users/UI/userOverview/UserOverviewTab';
import PartnersOverviewTab from 'src/partners/UI/partnerOverview/partnerOverviewTab';
import theme from 'styles/theme';
import constants from 'src/common/utils/constants';
import usePermissions from 'src/common/hooks/authenticationHook';

const styles = {
  tabPanel: {
    padding: '24px 0px',
    position: 'relative',
  },
  tabIndicator: {
    '& .MuiTabs-indicator': {
      width: '80px',
      background: `linear-gradient(to right, ${theme.palette.primary.dark} 46.35%, ${theme.palette.secondary.main} 80.73%, ${theme.palette.lightgrey.main} 100%)`,
      height: '4px',
    },
  },
  tabIndicatorLeft: {
    '& .MuiTabs-indicator': {
      width: '80px',
      background: `linear-gradient(to left, ${theme.palette.primary.dark} 46.35%, ${theme.palette.secondary.main} 80.73%, ${theme.palette.lightgrey.main} 100%)`,
      height: '4px',
    },
  },
  tab: {
    pl: 0,
    pr: 0,
    mr: 2,
  },
};

const Home: NextPage = () => {
  const [selectedTab, setSelectedTab] = useState('0');
  const router = useRouter();

  useEffect(() => {
    const org = router.query['eigandi'];
    if (org) {
      setSelectedTab('1');
    }
  }, [router.query]);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const { hasAdminAccess } = usePermissions();

  return (
    <>
      <Layout />
      <Box sx={{ ml: `calc(6% + ${constants.DRAWER_WIDTH}px)`, mr: '6%', minWidth: '600px' }}>
        <AuthGuard>
          <TabContext value={selectedTab}>
            <TabList onChange={handleChange} sx={selectedTab == '0' ? styles.tabIndicator : styles.tabIndicatorLeft}>
              <Tab label="Notendur" value="0" tabIndex={0} sx={styles.tab} />
              <Tab label="Eigendur" value="1" tabIndex={0} sx={styles.tab} />
              {hasAdminAccess && <Tab label="Samstarfsaðilar" value="2" tabIndex={0} sx={styles.tab} />}
            </TabList>
            <div style={{ position: 'relative' }}>
              <TabPanel value="0" sx={styles.tabPanel}>
                <UserOverviewTab />
              </TabPanel>
              <TabPanel value="1" sx={styles.tabPanel}>
                <OrganizationOverviewTab />
              </TabPanel>
              <TabPanel value="2" sx={styles.tabPanel}>
                <PartnersOverviewTab />
              </TabPanel>
            </div>
          </TabContext>
        </AuthGuard>
      </Box>
    </>
  );
};

export default Home;
