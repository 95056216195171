import { Button, FormControlLabel, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import AccordionComposite, { areAllPanelsExpanded, handleTogglePanel } from 'src/common/UI/layouts/AccordionComposite';
import { formatSsn } from 'src/common/utils/ssnUtil';
import Checkbox from 'src/common/UI/forms/Checkbox';
import Claimant from 'src/users/models/Claimant';
import usePermissions from 'src/common/hooks/authenticationHook';

interface ClaimantMultiSelectProps {
  title: string;
  claimantsSorted: { [ssn: string]: Claimant[] };
  selectedClaimants: string[];
  hasAccess: boolean;
  checkClaimant: (claimantNumber: string, isChecked: boolean) => void;
  checkOrUncheckAllClaimants: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, claimants: Claimant[]) => void;
  areAllClaimantsSelected: (claimants: Claimant[]) => boolean;
}

export default function ClaimantMultiSelect({
  title,
  claimantsSorted,
  selectedClaimants,
  checkClaimant,
  checkOrUncheckAllClaimants,
  areAllClaimantsSelected,
  hasAccess,
}: ClaimantMultiSelectProps): JSX.Element {
  const [claimantKeys, setClaimantKeys] = useState<string[]>([]);
  const [claimantValues, setClaimantValues] = useState<Claimant[]>([]);
  const [expandedPanels, setExpandedPanels] = useState<{ [index: string]: boolean }>(
    mapExpandedPanels(false, claimantsSorted)
  );
  const { isAllowedToSave } = usePermissions();

  useEffect(() => {
    setClaimantKeys(Object.keys(claimantsSorted));
    setClaimantValues(Object.values(claimantsSorted).flat());
  }, [claimantsSorted]);

  function toggleAll() {
    if (areAllPanelsExpanded(expandedPanels)) {
      setExpandedPanels(mapExpandedPanels(false, claimantsSorted));
    } else {
      setExpandedPanels(mapExpandedPanels(true, claimantsSorted));
    }
  }

  function mapExpandedPanels(bool: boolean, claimantsSorted: { [ssno: string]: Claimant[] }) {
    const claimantSSNOList = Object.keys(claimantsSorted);
    const expandedPanels: { [index: string]: boolean } = {};
    for (let i = 0; i < claimantSSNOList.length; i++) {
      const index: string = claimantSSNOList[i];
      expandedPanels[index] = bool;
    }
    return expandedPanels;
  }

  function getTotalSelectedOfClaimant(claimants: Claimant[]) {
    let totalSelected = 0;
    for (let i = 0; i < claimants.length; i++) {
      if (selectedClaimants?.includes(claimants[i].number)) {
        totalSelected++;
      }
    }
    return totalSelected;
  }

  function claimantAccordionTitle(ssn: string) {
    const claimants = claimantsSorted[ssn];
    const name = claimants[0].name2;
    const totalSelected = getTotalSelectedOfClaimant(claimants);
    return `${name} ${formatSsn(ssn)} (${totalSelected} / ${claimants.length} kröfuhafanúmer)`;
  }
  return (
    <div style={{ opacity: hasAccess ? 1 : 0.5 }}>
      <Grid container>
        {claimantKeys.length > 0 ? (
          <>
            <Grid item xs={12}>
              <Typography fontSize={18}>{title}</Typography>
            </Grid>
            <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start', mt: 1 }}>
              <Button
                disabled={!hasAccess}
                onClick={() => {
                  toggleAll();
                }}
                sx={{ pt: 0, textTransform: 'none' }}
                variant="text"
              >
                <Typography>{areAllPanelsExpanded(expandedPanels) ? 'Loka öllum' : 'Opna alla'}</Typography>
              </Button>
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <>
                <Typography variant="body2" display={'inline'} sx={{ mt: 1.5 }}>
                  Velja allt
                </Typography>
                <Checkbox
                  disabled={!isAllowedToSave || !hasAccess}
                  sx={{ mr: 1 }}
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                    checkOrUncheckAllClaimants(e, claimantValues)
                  }
                  checked={areAllClaimantsSelected(claimantValues)}
                />
              </>
            </Grid>
          </>
        ) : null}
      </Grid>
      <AccordionComposite
        contents={claimantKeys}
        expandedPanels={expandedPanels}
        setExpandedPanels={setExpandedPanels}
        onPanelToggle={handleTogglePanel}
        hasAccess={hasAccess}
        summary={(ssn: string) => {
          return (
            <Grid container>
              <Grid item xs={11} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography variant="body2">{claimantAccordionTitle(ssn)}</Typography>
              </Grid>
              <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Checkbox
                  disabled={!isAllowedToSave || !hasAccess}
                  sx={{ p: 0 }}
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                    checkOrUncheckAllClaimants(e, claimantsSorted[ssn])
                  }
                  checked={areAllClaimantsSelected(claimantsSorted[ssn])}
                />
              </Grid>
            </Grid>
          );
        }}
        details={(ssn: string) => {
          return (
            <Grid container>
              {claimantsSorted[ssn].map((claimant: Claimant, id2: number) => (
                <Grid item xs={12} lg={6} key={id2 + '-claimant'}>
                  <FormControlLabel
                    disabled={!isAllowedToSave}
                    labelPlacement="end"
                    key={id2 + '-form'}
                    label={
                      <Typography variant="body2" display="inline">
                        {claimant.number} {claimant.name ? '- ' + claimant.name : ''}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        disabled={!isAllowedToSave || !hasAccess}
                        onClick={() => checkClaimant(claimant.number, selectedClaimants?.includes(claimant.number))}
                        checked={selectedClaimants?.includes(claimant.number)}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
          );
        }}
      />
    </div>
  );
}
