import { Checkbox, Typography, styled } from '@mui/material';
import { useState, useEffect } from 'react';
import { formatSsn } from 'src/common/utils/ssnUtil';
import { OrganizationSuggestion } from 'src/organizations/models/OrganizationSearchSuggestion';
import LoadingSpinner from 'src/common/UI/loading/LoadingSpinner';
import Banner from 'src/common/UI/banners/Banner';
import { api } from 'pages/api/trpc/_api';
import { Organization } from 'src/organizations/models/Organization';
import { noCacheAndNoRefetchOptions } from 'src/common/utils/noCacheAndNoRefetchOptions';
import { ClaimTemplate } from 'src/organizations/models/ClaimTemplate';
import CustomPanel from 'src/common/UI/layouts/CustomPanel';
import { Tag } from '@motusehf/web-ui-component-library/dist';

interface EditClaimTemplateProps {
  userId?: string;
  organization: OrganizationSuggestion | Organization;
  organizationId?: string;
  claimTemplates: string[];
  handleClaimTemplateChange: (claimTemplates: string[]) => void;
}

const GroupContainer = styled('div')<{ hasAccess: boolean }>(({ theme, hasAccess }) => ({
  alignItems: 'center',
  padding: theme.spacing(1.5, 4),
  opacity: hasAccess ? 1 : 0.5,
}));

export default function ClaimTemplatePanel({
  userId,
  organization,
  claimTemplates,
  handleClaimTemplateChange,
  organizationId,
}: EditClaimTemplateProps) {
  const [orgClaimTemplates, setOrgClaimTemplates] = useState<ClaimTemplate[]>([]);
  const [selectedClaimTemplates, setSelectedClaimTemplates] = useState<string[]>(claimTemplates);
  const claimTemplateInitialData = api.organizations.getOrganizationClaimTemplates.useQuery(
    organization?.id,
    noCacheAndNoRefetchOptions
  );
  const organizationState = api.organizations.getOrganization.useQuery(organizationId || '', {
    ...noCacheAndNoRefetchOptions,
    enabled: !!organizationId,
  });
  const userState = userId ? api.users.getUser.useQuery(userId, noCacheAndNoRefetchOptions) : undefined;
  const hasUserClaimCreationAccess = userState?.data?.organization?.hasAccessToClaimCreation;

  let hasOrganizationAccess = false;
  if ('hasAccessToClaimCreation' in organization) {
    hasOrganizationAccess = organization.hasAccessToClaimCreation;
  } else {
    hasOrganizationAccess = organizationState?.data?.hasAccessToClaimCreation || false;
  }
  const hasAccess = hasUserClaimCreationAccess ?? hasOrganizationAccess;

  useEffect(() => {
    handleClaimTemplateChange(selectedClaimTemplates);
  }, [handleClaimTemplateChange, selectedClaimTemplates]);

  useEffect(() => {
    if (claimTemplateInitialData.data) {
      setOrgClaimTemplates(claimTemplateInitialData.data);
    }
  }, [claimTemplateInitialData.data]);

  if (claimTemplateInitialData.isLoading) {
    return <LoadingSpinner></LoadingSpinner>;
  } else if (claimTemplateInitialData.error) {
    return <Banner type="error">{claimTemplateInitialData.error.message}</Banner>;
  }

  const toggleClaimTemplateSelection = (claimTemplate: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedClaimTemplates((prevState: string[]) => {
        return prevState.filter((x) => x !== claimTemplate);
      });
    } else {
      setSelectedClaimTemplates((prevState: string[]) => {
        return [...prevState, claimTemplate];
      });
    }
  };

  return (
    <CustomPanel label={'Tengd félög'} borderColor="transparent">
      {orgClaimTemplates
        .sort((a, b) => (b.isActive === true ? 1 : 0) - (a.isActive === true ? 1 : 0))
        .map((claimTemplate, index) => (
          <GroupContainer
            hasAccess={hasAccess}
            style={{
              backgroundColor: index % 2 === 0 ? '#EBEEED' : '#FAFAFA',
              opacity: hasAccess ? 1 : 0.5,
            }}
            key={claimTemplate.id + '-claimTemplate'}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '140px 1fr auto',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <div style={{ width: '100px', textAlign: 'left' }}>
                {claimTemplate.isActive ? (
                  <Tag label="Virkur" color="#D5E7D2" />
                ) : (
                  <Tag label="Óvirkur" color="#EEEEEE" />
                )}
              </div>
              <Typography display="inline" sx={{ color: '#333333', fontWeight: '400' }}>
                {claimTemplate.name + ` - (${formatSsn(claimTemplate.claimantSsn)})`}
              </Typography>
              <Checkbox
                onClick={() =>
                  toggleClaimTemplateSelection(claimTemplate.id, selectedClaimTemplates?.includes(claimTemplate.id))
                }
                checked={selectedClaimTemplates?.includes(claimTemplate.id)}
                disabled={!hasAccess}
              />
            </div>
          </GroupContainer>
        ))}
    </CustomPanel>
  );
}
