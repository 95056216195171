import * as React from 'react';
import Typography from '@mui/material/Typography';
import isEmail from 'validator/lib/isEmail';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Link, Tab } from '@mui/material';
import Panel from 'src/common/UI/layouts/Panel';
import { useState } from 'react';
import { CreateUserRequest } from 'src/users/models/CreateUserRequest';
import ValidateInputField from 'src/common/UI/forms/ValidateInputField';
import TextInput from 'src/common/UI/forms/TextInput';
import ClaimantsPanel from './ClaimantsPanel';
import SearchInput from 'src/common/UI/forms/SearchInput';
import NationalRegistryEntry from 'src/common/models/NationalRegistryEntry';
import NationalRegistryService from 'src/common/services/nationalRegistryService';
import OrganizationSuggestionService from 'src/organizations/services/organizationSuggestionService';
import { OrganizationSuggestion } from 'src/organizations/models/OrganizationSearchSuggestion';
import AccessPrivilegesPanel from './AccessPrivilegesPanel';
import { trpcClient } from 'pages/api/trpc/_api';
import { toast } from 'react-toastify';
import ToastSuccess from 'src/common/UI/toasts/ToastSuccess';
import Banner from 'src/common/UI/banners/Banner';
import usePermissions from 'src/common/hooks/authenticationHook';
import theme from 'styles/theme';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CustomPanel from 'src/common/UI/layouts/CustomPanel';
import ClaimantRoles from './ClaimantRoles';
import ClaimTemplatePanel from './ClaimTemplatePanel';

const styles = {
  confirmButton: {
    backgroundColor: '#375046',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '8px',
    padding: '10px 22px 11px 25px',
  },
  cancelButton: {
    color: '#666666',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '7px',
    border: '1px solid #666666',
    padding: '10px 16px',
  },
  foreignLink: {
    color: '#73AF69',
    fontWeight: 400,
    textDecoration: 'none',
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
};

const stylesTab = {
  tabPanel: {
    padding: '24px 0px',
    position: 'relative',
  },
  tabIndicator: {
    '& .MuiTabs-indicator': {
      width: '80px',
      background: `linear-gradient(to right, ${theme.palette.primary.dark} 46.35%, ${theme.palette.secondary.main} 80.73%, ${theme.palette.lightgrey.main} 100%)`,
      height: '4px',
    },
  },
  tabIndicatorLeft: {
    '& .MuiTabs-indicator': {
      width: '80px',
      background: `linear-gradient(to left, ${theme.palette.primary.dark} 46.35%, ${theme.palette.secondary.main} 80.73%, ${theme.palette.lightgrey.main} 100%)`,
      height: '4px',
    },
  },
  tab: {
    pl: 0,
    pr: 0,
    mr: 2,
  },
};

export interface SavedCreateAnotherState {
  organization: OrganizationSuggestion;
  roles: string[];
}

interface Props {
  children?: React.ReactNode;
  savedState: SavedCreateAnotherState | null;
  onSuccess: (savedState: SavedCreateAnotherState | null) => void;
  onFailure: () => void;
  onCancel: () => void;
  onSubmit: () => void;
}

export default function CreateUserForm({ savedState, onSuccess, onFailure, onCancel, onSubmit }: Props) {
  const [email, setEmail] = useState<string>('');
  const [nationalRegistryEntry, setNationalRegistryEntry] = useState<NationalRegistryEntry | null>(null);
  const [organization, setOrganization] = useState<OrganizationSuggestion | null>(null);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isForeign, setIsForeign] = useState<boolean>(false);
  const [shouldCreateAnother, setShouldCreateAnother] = useState<boolean>(false);
  const [userRoles, setUserRoles] = useState<string[]>(savedState?.roles ?? []);
  const [radioButtonRoles, setRadioButtonRoles] = useState<string[]>(savedState?.roles ?? []);
  const [claimants, setClaimants] = useState<string[]>([]);
  const [claimTemplate, setClaimTemplate] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const { isAllowedToSave } = usePermissions();
  const [selectedTab, setSelectedTab] = useState('0');

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  React.useEffect(() => {
    if (!savedState) return;

    setUserRoles(savedState.roles);
    setOrganization(savedState.organization);
    setShouldCreateAnother(true);
  }, [savedState]);

  const nationalRegistryService = new NationalRegistryService();
  const organizationService = new OrganizationSuggestionService();

  const handleCreateAnotherChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShouldCreateAnother(e.target.checked);
  };

  const onEmailChange = (value: string, isValid: boolean): void => {
    setIsEmailValid(isValid);
    setEmail(value);
  };

  const onAccessPrivilegeChange = (roleIds: string[], isSelected: boolean): void => {
    if (isSelected) {
      setUserRoles((prevState) => {
        const newRoles = [...prevState, ...roleIds];
        return newRoles.filter((item, index) => newRoles.indexOf(item) === index);
      });
    } else {
      setUserRoles((prevState) => {
        return prevState.filter((x) => {
          return !roleIds.includes(x);
        });
      });
    }
  };

  const onIsForeignChange = (): void => {
    setNationalRegistryEntry(null);
    setIsForeign((prevState: boolean) => !prevState);
  };

  const onForeignNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const user = new NationalRegistryEntry();
    user.name = event.target.value;
    setNationalRegistryEntry(user);
  };

  function isStateValid() {
    return isEmailValid && organization !== null && nationalRegistryEntry !== null;
  }
  const handleRoleChange = React.useCallback((value: string) => {
    if (!value || value === '') {
      setRadioButtonRoles((prevState: string[]) => prevState.filter((role: string) => !role.startsWith('Claim')));
    } else {
      setRadioButtonRoles((prevState: string[]) => [
        ...prevState.filter((role: string) => !role.startsWith('Claim')),
        value,
      ]);
    }
  }, []);

  const handleSaveChanges = (): void => {
    if (!isStateValid || !organization || !nationalRegistryEntry || isSaving || !isAllowedToSave) return;
    setIsSaving(true);
    onSubmit();
    const req: CreateUserRequest = {
      name: nationalRegistryEntry.name,
      organizationId: organization.id,
      email: email,
      userName: null,
      phoneNumber: null,
      ssn: isForeign ? null : nationalRegistryEntry.ssn,
      activityState: 'Onboarding',
      userType: 'Claimant',
      roles: [...userRoles, ...radioButtonRoles],
      claimantNumbers: claimants,
      claimTemplates: claimTemplate,
    };
    trpcClient.users.createUser
      .mutate(req)
      .then(
        () => {
          toast(<ToastSuccess message={'Nýr notandi hefur verið stofnaður og mun viðkomandi fá póst þess efnis.'} />);
          onSuccess(shouldCreateAnother ? { roles: userRoles, organization } : null);
        },
        () => {
          setErrorMessage('Hér hefur eitthvað farið úrskeiðis');
          onFailure();
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };
  const onDismissError = () => {
    setErrorMessage('');
  };

  return (
    <>
      <Box sx={{ mb: -3 }}>
        <Typography variant="h1" display="inline" sx={{ marginRight: '12px' }}>
          Stofna notanda
        </Typography>
      </Box>
      <Panel label="Notandi">
        <Box sx={{ mb: 3 }} />
        <Grid container rowSpacing="34px" columnSpacing="60px">
          <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
            <Link href={'#'} onClick={onIsForeignChange} sx={styles.foreignLink}>
              {isForeign ? 'Íslenskur aðili' : 'Erlendur aðili'}
            </Link>
            {isForeign ? (
              <TextInput label="Nafn notanda (einstaklings)" onChange={onForeignNameChange} />
            ) : (
              <SearchInput<NationalRegistryEntry>
                searchSuggestionService={nationalRegistryService}
                onValue={setNationalRegistryEntry}
                label="Nafn eða kennitala notanda (einstaklings)"
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <SearchInput<OrganizationSuggestion>
              searchSuggestionService={organizationService}
              initialValue={savedState?.organization}
              onValue={setOrganization}
              label="Nafn eða kennitala lögaðila (eigandi aðgangsins)"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ValidateInputField
              requiredText="Vantar netfang"
              errorText="Þetta er ekki gilt netfang"
              label="Netfang notanda"
              value={email}
              validator={isEmail}
              minLength={0}
              handleChange={onEmailChange}
            />
          </Grid>
        </Grid>
      </Panel>
      <CustomPanel>
        <TabContext value={selectedTab}>
          <div style={{ display: 'flex' }}>
            <div style={{ flexBasis: '20%' }}></div>
            <TabList
              onChange={handleChange}
              sx={selectedTab == '0' ? stylesTab.tabIndicator : stylesTab.tabIndicatorLeft}
            >
              <Tab label="Innheimta" value="0" tabIndex={0} sx={stylesTab.tab} />
              <Tab label="Kröfustofnun" value="1" tabIndex={0} sx={stylesTab.tab} />
            </TabList>
          </div>
          <div style={{ position: 'relative' }}>
            <TabPanel value="0" sx={stylesTab.tabPanel}>
              <AccessPrivilegesPanel
                hasPresetPrivileges={savedState != null}
                checkboxKeys={userRoles}
                handleCheckboxChange={onAccessPrivilegeChange}
                organizationId={organization?.id}
              />
              {organization ? (
                <ClaimantsPanel
                  user={null}
                  organization={organization}
                  organizationId={organization?.id}
                  claimants={claimants}
                  handleClaimantsChange={setClaimants}
                />
              ) : null}
            </TabPanel>
            <TabPanel value="1" sx={stylesTab.tabPanel}>
              <ClaimantRoles handleRoleChange={handleRoleChange} organizationId={organization?.id} />
              {organization ? (
                <>
                  <ClaimTemplatePanel
                    organizationId={organization?.id}
                    organization={organization}
                    claimTemplates={claimTemplate}
                    handleClaimTemplateChange={setClaimTemplate}
                  />
                </>
              ) : null}
            </TabPanel>
          </div>
        </TabContext>
      </CustomPanel>

      <Panel label="">
        {errorMessage && (
          <Grid container justifyContent="flex-end" spacing={2} sx={{ mb: 2 }}>
            <Banner type="error" onDismissed={onDismissError}>
              {errorMessage}
            </Banner>
          </Grid>
        )}
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={shouldCreateAnother} onChange={handleCreateAnotherChange} />}
                label="Stofna annan notanda"
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <Button variant="outlined" sx={styles.cancelButton} onClick={onCancel}>
              Hætta við
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={isSaving || !isStateValid() || !isAllowedToSave}
              sx={styles.confirmButton}
              onClick={handleSaveChanges}
            >
              Vista
            </Button>
          </Grid>
        </Grid>
      </Panel>
    </>
  );
}
