import { Box, Grid } from '@mui/material';
import { RemoveCircleOutline, InfoOutlined, CheckCircleOutlined, WarningAmber } from '@mui/icons-material';
import BannerType from './bannerType';

interface BannerIconProps {
  type: BannerType;
}

export default function BannerIcon({ type }: BannerIconProps) {
  function BannerIconType() {
    switch (type) {
      case 'success':
        return <CheckCircleOutlined sx={{ color: 'white', margin: '5px' }}></CheckCircleOutlined>;
      case 'info':
        return <InfoOutlined sx={{ color: 'white', margin: '5px' }}></InfoOutlined>;
      case 'warning':
        return <WarningAmber sx={{ color: 'white', margin: '5px' }}></WarningAmber>;
      case 'error':
        return <RemoveCircleOutline sx={{ color: 'white', margin: '5px' }}></RemoveCircleOutline>;
      default:
        return <></>;
    }
  }

  function getIconColor() {
    switch (type) {
      case 'success':
        return 'success.main';
      case 'info':
        return 'info.main';
      case 'warning':
        return 'warning.main';
      case 'error':
        return 'danger.main';
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: getIconColor(),
        borderRadius: '5px',
        position: 'relative',
      }}
    >
      <Grid container justifyContent="center">
        <BannerIconType></BannerIconType>
      </Grid>
    </Box>
  );
}
