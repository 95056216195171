import { Box, Grid, Backdrop, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import FullscreenModal from 'src/common/UI/layouts/FullscreenModal';
import CreateUserForm, { SavedCreateAnotherState } from '../upsertUser/CreateUserForm';
import EditUserForm from '../upsertUser/EditUserForm/EditUserForm';
import UserTable from './UserTable';
import CreateButton from 'src/common/UI/forms/CreateButton';
import LoadingSpinner from 'src/common/UI/loading/LoadingSpinner';
import SearchResponse from 'src/users/models/SearchResponse';
import Banner from 'src/common/UI/banners/Banner';
import Image from 'next/image';
import { ModalCancelControl } from '../../../common/UI/layouts/ModalCancelControl';
import { UserSearchRequest } from 'src/users/models/userSearchRequest';
import { trpcClient } from 'pages/api/trpc/_api';
import UserSearch from 'src/users/models/UserSearchResult';
import User from 'src/users/models/User';
import CreatePartnerUserForm from 'src/partners/partnerUser/UI/partnerUserOverview/upsertPartnerUser/CreatePartnerUserForm';
import EditPartnerUserForm from 'src/partners/partnerUser/UI/partnerUserOverview/upsertPartnerUser/EditPartnerUserForm';
import usePermissions from 'src/common/hooks/authenticationHook';
const defaultPageSize = 12;
import { DefaultDropdownButton } from '@motusehf/web-ui-component-library/dist';

const defaultSearchRequest: UserSearchRequest = {
  pageSize: defaultPageSize,
  page: 0,
  searchString: '',
  userType: 'Claimant',
};

export default function UserOverviewTab(): JSX.Element {
  const [userSearchResponse, setUserSearchResponse] = useState<SearchResponse<UserSearch>>({ hits: [], total: 0 });
  const [searchBody, setSearchBody] = useState(defaultSearchRequest);
  const [isLoadingMoreUsers, setIsLoadingMoreUsers] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState('');
  const [exitError, setExitError] = useState('');
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [selectedPartnerUser, setSelectedPartnerUser] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [savedState, setSavedState] = useState<SavedCreateAnotherState | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState<string>('');

  useEffect(() => {
    async function fetchUsers(shouldAppend: boolean) {
      await trpcClient.users.searchUsers.query(searchBody).then(
        (data) => {
          setUserSearchResponse((prevState) => {
            return shouldAppend ? { hits: prevState.hits.concat(data.hits), total: data.total } : data;
          });
        },
        (error) => {
          setError(error.message);
        }
      );
      setIsLoading(false);
      setIsLoadingMoreUsers(false);
    }

    fetchUsers(searchBody.page !== 0);
  }, [searchBody]);

  const selectOpenUser = (userId: string, userType: string) => {
    setExitError('');
    setSelectedOption('');
    if (userType === 'Claimant') {
      setSelectedPartnerUser('');
      setSelectedUser(userId);
    } else if (userType === 'Partner') {
      setSelectedUser('');
      setSelectedPartnerUser(userId);
    }
    handleOpen();
  };

  const handleOpenModal = () => {
    if (selectedOption === 'Notanda') {
      setSelectedUser('');
    } else if (selectedOption === 'Kerfisnotandi') {
      setSelectedPartnerUser('');
    }
    handleOpen();
  };

  const handleFailure = () => {
    setIsOpen(true);
    setIsSubmitting(false);
  };

  const handleSuccessUpdate = (updatedUser: User) => {
    setUserSearchResponse((prevState: SearchResponse<UserSearch>) => {
      const u = prevState.hits.findIndex((u) => u.id === updatedUser.id);
      prevState.hits[u].activityState = updatedUser.activityState;
      prevState.hits[u].email = updatedUser.email;
      prevState.hits[u].name = updatedUser.name;
      prevState.hits[u].userName = updatedUser.userName;
      prevState.hits[u].ssn = updatedUser.ssn;
      prevState.hits[u].userType = updatedUser.userType;
      prevState.hits[u].organizationSsn = updatedUser.organization?.ssn;
      prevState.hits[u].organizationName = updatedUser.organization?.name;
      return prevState;
    });
    setIsOpen(false);
    setIsSubmitting(false);
  };

  const handleSuccessCreate = (savedState: SavedCreateAnotherState | null) => {
    setIsOpen(false);
    setIsSubmitting(false);
    if (savedState) {
      setSavedState(savedState);
      setTimeout(() => handleOpenModal(), 500);
    }
  };

  const handleSuccessCreatePartnerUser = () => {
    setIsOpen(false);
    setIsSubmitting(false);
  };

  const handleOpen = () => setIsOpen(true);
  const errorFocusEl = document.getElementById('errorFocus');
  const handleClose = () => {
    if (modalCancelControl.isSafe) {
      setExitError('');
      setIsOpen(false);
    } else {
      setExitError(
        'Þú hefur gert breytingar á stillingum notenda, veldu „Vista“ til að staðfesta breytingar eða „Hætta við“.'
      );
      errorFocusEl?.focus();
    }
  };

  const handleDropdownSelection = (option: string) => {
    setSelectedOption(option);
    setSelectedUser('');
    setSelectedPartnerUser('');
    handleOpenModal();
  };

  const modalCancelControl: ModalCancelControl = {
    isSafe: true,
    closeFunc: handleClose,
  };

  const handleOpenCreateModal = () => {
    setSelectedOption('Notanda'); // Sets a default value
    setSelectedUser('');
    setSelectedPartnerUser('');
    handleOpen();
  };

  const userInputChange = (e: { target: { value: string } }) => {
    setSearchBody((prevState) => {
      return {
        ...prevState,
        searchString: e.target.value,
        page: 0,
      };
    });
  };

  function onFetchMoreUsers(): void {
    setIsLoadingMoreUsers(true);
    setSearchBody((prevState) => {
      return { ...prevState, page: prevState.page + 1 };
    });
  }
  const { hasAdminAccess } = usePermissions();
  return (
    <>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        textAlign="center"
        position="absolute"
        top="-32px"
        margin="0px"
        width="auto"
        right="0px"
      >
        {hasAdminAccess ? (
          <DefaultDropdownButton
            options={['Notanda', 'Kerfisnotanda']}
            text="Bæta við"
            onSelect={handleDropdownSelection}
            buttonWidth="130px"
          />
        ) : (
          <CreateButton
            text="Stofna notanda"
            icon={<Image color="primary.dark" src="/icons/person.svg" alt="notandi" width="24" height="24" />}
            handleOpenCreateModal={handleOpenCreateModal}
          />
        )}
      </Grid>
      <Box sx={{ mb: 3 }}></Box>
      {error && (
        <Banner sx={{ paddingBottom: '1em' }} type="error">
          {error}
        </Banner>
      )}{' '}
      <UserTable
        selectUser={selectOpenUser}
        users={userSearchResponse.hits}
        handleUserInputChange={userInputChange}
        onFetchMoreUsers={onFetchMoreUsers}
        isLoadingMoreUsers={isLoadingMoreUsers}
        shouldShowFetchMore={userSearchResponse.total > userSearchResponse.hits.length}
        userCount={userSearchResponse.total}
      />
      <Box sx={{ mt: 3 }}>{isLoading ? <LoadingSpinner /> : null}</Box>
      <FullscreenModal isOpen={isOpen} handleClose={handleClose}>
        {selectedOption === 'Notanda' ? (
          <CreateUserForm
            savedState={savedState}
            onSuccess={handleSuccessCreate}
            onFailure={handleFailure}
            onCancel={() => setIsOpen(false)}
            onSubmit={() => setIsSubmitting(true)}
          />
        ) : selectedOption === 'Kerfisnotanda' ? (
          <CreatePartnerUserForm
            savedState={savedState}
            onSuccess={handleSuccessCreatePartnerUser}
            onFailure={handleFailure}
            onCancel={() => setIsOpen(false)}
            onSubmit={() => setIsSubmitting(true)}
          />
        ) : selectedUser ? (
          <>
            <EditUserForm
              userId={selectedUser}
              onSuccess={handleSuccessUpdate}
              onFailure={handleFailure}
              onSubmit={() => setIsSubmitting(true)}
              modalCancelControl={modalCancelControl}
            />{' '}
            <Box
              tabIndex={-1}
              id="errorFocus"
              sx={{
                mt: 2,
              }}
            >
              {exitError && (
                <Banner isDismissible={false} type="error">
                  {exitError}
                </Banner>
              )}
            </Box>
          </>
        ) : selectedPartnerUser ? (
          <>
            <EditPartnerUserForm
              userId={selectedPartnerUser}
              onSuccess={handleSuccessUpdate}
              onFailure={handleFailure}
              onSubmit={() => setIsSubmitting(true)}
              modalCancelControl={modalCancelControl}
            />
            <Box
              tabIndex={-1}
              id="errorFocus"
              sx={{
                mt: 2,
              }}
            >
              {exitError && (
                <Banner isDismissible={false} type="error">
                  {exitError}
                </Banner>
              )}
            </Box>
          </>
        ) : null}
      </FullscreenModal>
      <Backdrop open={isSubmitting} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 400 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
