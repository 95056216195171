import { CircularProgress, Grid, Typography } from '@mui/material';

export default function LoadingSpinner() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '50%' }}
    >
      <Grid item xs={3} sx={{ textAlign: 'center' }}>
        <CircularProgress></CircularProgress>
        <Typography variant="body2">Augnablik...</Typography>
      </Grid>
    </Grid>
  );
}
