import { Divider, Grid, Typography } from '@mui/material';

interface PanelProps {
  children: React.ReactNode;
  label: string;
}

export default function Panel(props: PanelProps) {
  return (
    <>
      <Divider sx={{ mt: 8, mb: 5 }} />
      <Grid container columns={{ xs: 5 }} sx={{ minWidth: '600px' }}>
        <Grid item xs={1}>
          <Typography variant="h4" component="h2">
            {props.label}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {props.children}
        </Grid>
      </Grid>
    </>
  );
}
