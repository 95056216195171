import { Grid, Box, Backdrop, CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import DomainIcon from '@mui/icons-material/Domain';
import FullscreenModal from 'src/common/UI/layouts/FullscreenModal';
import { Organization } from 'src/organizations/models/Organization';
import SearchResponse from 'src/users/models/SearchResponse';
import CreateOrganizationForm from '../upsertOrganization/CreateOrganizationForm';
import OrganizationTable from './OrganizationTable';
import EditOrganizationForm from '../upsertOrganization/EditOrganizationForm';
import LoadingSpinner from 'src/common/UI/loading/LoadingSpinner';
import Banner from 'src/common/UI/banners/Banner';
import CreateButton from 'src/common/UI/forms/CreateButton';
import { useRouter } from 'next/router';
import { ModalCancelControl } from 'src/common/UI/layouts/ModalCancelControl';
import { trpcClient } from 'pages/api/trpc/_api';
import OrganizationSearchRequestSchema from 'src/organizations/models/organizationSearchRequest';
import { z } from 'zod';

const defaultPageSize = 12;

const defaultSearchRequest: z.infer<typeof OrganizationSearchRequestSchema> = {
  pageSize: defaultPageSize,
  page: 0,
  searchString: '',
  shouldSearchClaimants: true,
};

export default function OrganizationOverviewTab(): JSX.Element {
  const [searchResponse, setSearchResponse] = useState<SearchResponse<Organization>>({ hits: [], total: 0 });
  const [searchBody, setSearchBody] = useState(defaultSearchRequest);
  const [error, setError] = useState('');
  const [exitError, setExitError] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();

  useEffect(() => {
    const org = router.query['eigandi'];

    if (org) {
      setSelectedOrganizationId(org as string);
      setIsModalOpen(true);
    }
  }, [router.query]);

  useEffect(() => {
    async function fetchOrganizations(shouldAppend: boolean) {
      await trpcClient.organizations.searchOrganizations.query(searchBody).then(
        (data) => {
          setSearchResponse((prevState) => {
            return shouldAppend ? { hits: prevState.hits.concat(data.hits), total: data.total } : data;
          });
        },
        (error) => {
          setError(error.message);
        }
      );

      setIsLoading(false);
      setIsLoadingMore(false);
    }
    fetchOrganizations(searchBody.page !== 0);
  }, [searchBody]);

  const onSearchInputChange = (e: { target: { value: string } }) => {
    setSearchBody((prevState) => {
      return {
        ...prevState,
        searchString: e.target.value,
        page: 0,
      };
    });
  };

  const onOrganizationSelect = (organizationId: string): void => {
    setExitError('');
    setSelectedOrganizationId(organizationId);
    router.replace(`/?eigandi=${organizationId}`, undefined);
    setIsModalOpen(true);
  };

  function onFetchMore(): void {
    setIsLoadingMore(true);
    setSearchBody((prevState) => {
      return { ...prevState, page: prevState.page + 1 };
    });
  }

  const handleOpenCreateModal = () => {
    setSelectedOrganizationId(null);
    handleOpen();
  };

  const handleFailure = () => {
    setIsModalOpen(true);
    setIsSubmitting(false);
  };

  const handleSuccess = () => {
    router.replace('/', undefined);
    setIsModalOpen(false);
    setIsSubmitting(false);
  };

  const handleOpen = () => setIsModalOpen(true);

  useEffect(() => {
    if (!isModalOpen && router.asPath.length > 1) {
      router.replace('/', undefined);
    }
  }, [isModalOpen, router]);

  const errorFocusEl = document.getElementById('errorFocus');
  const handleModalClose = () => {
    if (modalCancelControl.isSafe) {
      setExitError('');
      setSelectedOrganizationId(null);
      setIsSubmitting(false);
      setIsModalOpen(false);
    } else {
      setExitError(
        'Þú hefur gert breytingar á stillingum eiganda, veldu „Vista“ til að staðfesta breytingar eða „Hætta við“.'
      );
      errorFocusEl?.focus();
    }
  };
  const modalCancelControl: ModalCancelControl = {
    isSafe: true,
    closeFunc: handleModalClose,
  };

  return (
    <>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        textAlign="center"
        position="absolute"
        top="-29px"
        margin="0px"
        width="auto"
        right="0px"
      >
        <CreateButton
          text="Stofna eiganda"
          icon={<DomainIcon sx={{ color: 'primary.dark' }} />}
          handleOpenCreateModal={handleOpenCreateModal}
        />
      </Grid>
      <Box sx={{ mb: 3 }}></Box>
      {error && (
        <Banner sx={{ paddingBottom: '1em' }} type="error">
          {error}
        </Banner>
      )}
      <OrganizationTable
        organizations={searchResponse.hits}
        handleInputChange={onSearchInputChange}
        selectOrganization={onOrganizationSelect}
        onFetchMoreOrganizations={onFetchMore}
        isLoadingMoreOrganizations={isLoadingMore}
        shouldShowFetchMore={searchResponse.total > searchResponse.hits.length}
        organizationCount={searchResponse.total}
      />
      <Box sx={{ mt: 3 }}>{isLoading ? <LoadingSpinner /> : null}</Box>
      <FullscreenModal isOpen={isModalOpen} handleClose={handleModalClose}>
        {selectedOrganizationId ? (
          <>
            <EditOrganizationForm
              organizationId={selectedOrganizationId}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              onSubmit={() => setIsSubmitting(true)}
              modalCancelControl={modalCancelControl}
            />{' '}
            <Box
              tabIndex={-1}
              id="errorFocus"
              sx={{
                mt: 2,
              }}
            >
              {exitError && (
                <Banner isDismissible={false} type="error">
                  {exitError}
                </Banner>
              )}
            </Box>
          </>
        ) : (
          <CreateOrganizationForm
            onSuccess={handleSuccess}
            onFailure={handleFailure}
            onCancel={handleModalClose}
            onSubmit={() => setIsSubmitting(true)}
          />
        )}
      </FullscreenModal>
      <Backdrop open={isSubmitting} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 400 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
