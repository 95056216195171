import { SearchInputOption } from 'src/common/UI/forms/SearchInput';
import { formatSsn } from 'src/common/utils/ssnUtil';

export interface IOrganizationSuggestion extends SearchInputOption {
  name: string;
  ssn: string;
  id: string;
}

export class OrganizationSuggestion implements IOrganizationSuggestion {
  constructor() {
    this.ssn = '';
    this.name = '';
    this.id = '';
  }

  ssn: string;
  name: string;
  id: string;
  getKey = (): string => this.id.toString();
  getLabel = (): string => `${this.name} (kt. ${formatSsn(this.ssn)})`;
  compare = (other: SearchInputOption): boolean => {
    return this.id === (other as OrganizationSuggestion).id;
  };
}
