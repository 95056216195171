import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface ButtonProps {
  children: ReactNode;
  isLoading?: boolean;
  onClick?: () => void;
}

export default function CustomButton(props: ButtonProps) {
  return (
    <LoadingButton
      sx={{
        borderRadius: '20px',
        paddingTop: '17px',
        paddingBottom: '16px',
        paddingLeft: '28px',
        paddingRight: '28px',
        color: 'darkgrey.main',
      }}
      onClick={props.onClick}
      variant="contained"
      color="lightgrey"
      disableElevation
      loading={props.isLoading}
    >
      <Box sx={{ lineHeight: 1 }}>{props.children}</Box>
    </LoadingButton>
  );
}
