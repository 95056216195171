import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Image from 'next/image';
import PageHeader from './PageHeader';
import AccountMenu from './AccountControl';
import { useRouter } from 'next/router';

export default function Header() {
  const router = useRouter();
  return (
    <Box sx={{ flexGrow: 0 }}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar sx={{ pl: 0 }} disableGutters={true}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => router.push('/')}
            style={{ backgroundColor: 'transparent' }}
          >
            <Image src="/icons/Motus_logo.svg" alt="motus" width="120" height="120" priority={true} />
          </IconButton>
          <AccountMenu />
        </Toolbar>
        <PageHeader title="Aðgangsstýring"></PageHeader>
      </AppBar>
    </Box>
  );
}
