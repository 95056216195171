import moment from 'moment';

export function toDate(dateStr?: string) {
  if (!dateStr) {
    return '';
  }

  try {
    return moment(dateStr).format('DD.MM.YYYY');
  } catch (e) {
    return '';
  }
}
