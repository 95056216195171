import { Grid, Box, Backdrop, CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import FullscreenModal from 'src/common/UI/layouts/FullscreenModal';
import { Partner } from 'src/partners/models/Partner';
import SearchResponse from 'src/users/models/SearchResponse';
import CreatePartnersForm from './upsertPartners/CreatePartnersForm';
import PartnersTable from './PartnersTable';
import EditPartnersForm from '../partnerOverview/upsertPartners/EditPartnersForm';
import LoadingSpinner from 'src/common/UI/loading/LoadingSpinner';
import Banner from 'src/common/UI/banners/Banner';
import CreateButton from 'src/common/UI/forms/CreateButton';
import { useRouter } from 'next/router';
import { ModalCancelControl } from 'src/common/UI/layouts/ModalCancelControl';
import { trpcClient } from 'pages/api/trpc/_api';
import OrganizationSearchRequestSchema from 'src/organizations/models/organizationSearchRequest';
import { z } from 'zod';

const defaultPageSize = 12;

const defaultSearchRequest: z.infer<typeof OrganizationSearchRequestSchema> = {
  pageSize: defaultPageSize,
  page: 0,
  searchString: '',
  shouldSearchClaimants: true,
};

export default function PartnerOverviewTab(): JSX.Element {
  const [searchResponse, setSearchResponse] = useState<SearchResponse<Partner>>({ hits: [], total: 0 });
  const [searchBody, setSearchBody] = useState(defaultSearchRequest);
  const [error, setError] = useState('');
  const [exitError, setExitError] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();

  useEffect(() => {
    async function fetchPartners(shouldAppend: boolean) {
      await trpcClient.partners.searchPartners.query(searchBody).then(
        (data) => {
          setSearchResponse((prevState) => {
            return shouldAppend ? { hits: prevState.hits.concat(data.hits), total: data.total } : data;
          });
        },
        (error) => {
          setError(error.message);
        }
      );

      setIsLoading(false);
      setIsLoadingMore(false);
    }
    fetchPartners(searchBody.page !== 0);
  }, [searchBody]);

  const onSearchInputChange = (e: { target: { value: string } }) => {
    setSearchBody((prevState) => {
      return {
        ...prevState,
        searchString: e.target.value,
        page: 0,
      };
    });
  };

  const selectOpenPartner = (partnerId: string) => {
    setExitError('');
    setSelectedPartner(partnerId);
    handleOpen();
  };

  function onFetchMore(): void {
    setIsLoadingMore(true);
    setSearchBody((prevState) => {
      return { ...prevState, page: prevState.page + 1 };
    });
  }

  const handleOpenCreateModal = () => {
    setSelectedPartner('');
    handleOpen();
  };

  const handleFailure = () => {
    setIsModalOpen(true);
    setIsSubmitting(false);
  };

  const handleSuccess = () => {
    router.replace('/', undefined);
    setIsModalOpen(false);
    setIsSubmitting(false);
  };

  const handleOpen = () => setIsModalOpen(true);

  useEffect(() => {
    if (!isModalOpen && router.asPath.length > 1) {
      router.replace('/', undefined);
    }
  }, [isModalOpen, router]);

  const errorFocusEl = document.getElementById('errorFocus');
  const handleModalClose = () => {
    if (modalCancelControl.isSafe) {
      setExitError('');
      setIsSubmitting(false);
      setIsModalOpen(false);
    } else {
      setExitError(
        'Þú hefur gert breytingar á stillingum samstarfsaðila, veldu „Vista“ til að staðfesta breytingar eða „Hætta við“.'
      );
      errorFocusEl?.focus();
    }
  };
  const modalCancelControl: ModalCancelControl = {
    isSafe: true,
    closeFunc: handleModalClose,
  };

  return (
    <>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        textAlign="center"
        position="absolute"
        top="-29px"
        margin="0px"
        width="auto"
        right="0px"
      >
        <CreateButton
          text="Stofna samstarfsaðila"
          icon={<AddIcon sx={{ color: 'primary.dark' }} />}
          handleOpenCreateModal={handleOpenCreateModal}
        />
      </Grid>
      <Box sx={{ mb: 3 }}></Box>
      {error && (
        <Banner sx={{ paddingBottom: '1em' }} type="error">
          {error}
        </Banner>
      )}
      <PartnersTable
        partners={searchResponse.hits}
        handleInputChange={onSearchInputChange}
        selectPartner={selectOpenPartner}
        onFetchMorePartners={onFetchMore}
        isLoadingMorePartners={isLoadingMore}
        shouldShowFetchMore={searchResponse.total > searchResponse.hits.length}
        partnerCount={searchResponse.total}
      />
      <Box sx={{ mt: 3 }}>{isLoading ? <LoadingSpinner /> : null}</Box>
      <FullscreenModal isOpen={isModalOpen} handleClose={handleModalClose}>
        {selectedPartner ? (
          <>
            <EditPartnersForm
              partnerId={selectedPartner}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              onSubmit={() => setIsSubmitting(true)}
              modalCancelControl={modalCancelControl}
            />{' '}
            <Box
              tabIndex={-1}
              id="errorFocus"
              sx={{
                mt: 2,
              }}
            >
              {exitError && (
                <Banner isDismissible={false} type="error">
                  {exitError}
                </Banner>
              )}
            </Box>
          </>
        ) : (
          <CreatePartnersForm
            onSuccess={handleSuccess}
            onFailure={handleFailure}
            onCancel={handleModalClose}
            onSubmit={() => setIsSubmitting(true)}
          />
        )}
      </FullscreenModal>
      <Backdrop open={isSubmitting} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 400 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
