import { Box, CircularProgress, Typography } from '@mui/material';
import { signIn } from 'next-auth/react';
import Banner from 'src/common/UI/banners/Banner';
import usePermissions from 'src/common/hooks/authenticationHook';

interface AuthGuardProps {
  children: React.ReactNode;
}

export default function AuthGuard({ children }: AuthGuardProps) {
  const { hasAccessToApp, session, status } = usePermissions();

  if (status === 'loading') {
    return (
      <Box sx={{ position: 'absolute', margin: 0, top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <CircularProgress />
        <Typography sx={{ paddingTop: '45px', transform: 'translate(-35%, -50%)' }} variant="body1">
          Vinn úr auðkenningu...
        </Typography>
      </Box>
    );
  }

  if (status === 'unauthenticated' || session?.error) {
    signIn('demo-identity-server');
    return null;
  }

  if (!hasAccessToApp)
    return (
      <Banner sx={{ mt: '3em' }} type="error">
        Þú ert ekki með aðgang að þessum vefhluta.
      </Banner>
    );

  return <>{children}</>;
}
