import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid } from '@mui/material';
import Panel from 'src/common/UI/layouts/Panel';
import { useState } from 'react';
import { CreatePartnerUserRequest } from 'src/partners/models/CreatePartnerUserRequest';
import TextInput from 'src/common/UI/forms/TextInput';
import { api, trpcClient } from 'pages/api/trpc/_api';
import { toast } from 'react-toastify';
import ToastSuccess from 'src/common/UI/toasts/ToastSuccess';
import Banner from 'src/common/UI/banners/Banner';
import usePermissions from 'src/common/hooks/authenticationHook';
import { OrganizationSuggestion } from 'src/organizations/models/OrganizationSearchSuggestion';
import isEmail from 'validator/lib/isEmail';
import ValidateInputField from 'src/common/UI/forms/ValidateInputField';
import PartnerUserRoles from './PartnerUserRoles';
import { DropdownButton } from '@motusehf/web-ui-component-library/dist';
import { ExpandMore } from '@mui/icons-material';
import { PartnerUser } from 'src/partners/models/PartnerUser';

const styles = {
  confirmButton: {
    backgroundColor: '#375046',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '8px',
    padding: '10px 22px 11px 25px',
  },
  cancelButton: {
    color: '#666666',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '7px',
    border: '1px solid #666666',
    padding: '10px 16px',
  },
  foreignLink: {
    color: '#73AF69',
    fontWeight: 400,
    textDecoration: 'none',
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
};

export interface SavedCreateAnotherState {
  organization: OrganizationSuggestion;
  roles: string[];
}

interface Props {
  children?: React.ReactNode;
  savedState?: SavedCreateAnotherState | null;
  onSuccess: () => void;
  onFailure: () => void;
  onCancel: () => void;
  onSubmit: () => void;
}

interface DropdownOption {
  label: string;
  id: string | number;
}

export default function CreatePartnerUserForm({ savedState, onSuccess, onFailure, onCancel, onSubmit }: Props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const { isAllowedToSave } = usePermissions();
  const [nameOrSsn, setNameOrSsn] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [radioButtonRoles, setRadioButtonRoles] = useState<string[]>(savedState?.roles ?? []);
  const allPartnersState = api.partners.getAllPartners.useQuery();
  const [partnerId, setPartnerId] = useState<string | null>(null);

  const onNameOrSsnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setNameOrSsn(event.target.value);
  };

  const onEmailChange = (value: string, isValid: boolean): void => {
    setIsEmailValid(isValid);
    setEmail(value);
  };

  const handleRoleChange = React.useCallback((value: string) => {
    if (!value || value === '') {
      setRadioButtonRoles((prevState: string[]) => prevState.filter((role: string) => !role.startsWith('Claim')));
    } else {
      setRadioButtonRoles((prevState: string[]) => [
        ...prevState.filter((role: string) => !role.startsWith('Claim')),
        value,
      ]);
    }
  }, []);

  function isStateValid() {
    return isEmailValid && nameOrSsn !== null && partnerId !== null;
  }

  const handleSaveChanges = (): void => {
    if (isSaving || !isAllowedToSave || !partnerId) return;
    setIsSaving(true);
    onSubmit();

    const req: CreatePartnerUserRequest = {
      email: email,
      name: nameOrSsn,
      partnerId: partnerId,
      roles: radioButtonRoles,
    };
    trpcClient.partners.createPartnerUser
      .mutate(req)
      .then(
        () => {
          toast(<ToastSuccess message={'Kerfisnotandi hefur verið stofnaður.'} />);
          onSuccess();
        },
        () => {
          setErrorMessage('Hér hefur eitthvað farið úrskeiðis');
          onFailure();
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };
  const onDismissError = () => {
    setErrorMessage('');
  };

  const handlePartnerSelect = (option: DropdownOption) => {
    setPartnerId(option.id.toString());
  };
  return (
    <>
      <Box sx={{ mb: -3 }}>
        <Typography variant="h1" display="inline" sx={{ marginRight: '12px' }}>
          Stofna kerfisnotanda
        </Typography>
      </Box>
      <Panel label="Notandi">
        <Box sx={{ mb: 3 }} />
        <Grid container rowSpacing="34px" columnSpacing="128px">
          <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
            <TextInput label="Heiti" onChange={onNameOrSsnChange} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <DropdownButton
              text="Velja samstarfsaðila"
              icon={<ExpandMore />}
              options={
                allPartnersState.data?.hits?.map((partner: PartnerUser) => ({
                  label: partner.name,
                  id: partner.id,
                })) || []
              }
              onSelect={handlePartnerSelect}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ValidateInputField
              requiredText="Vantar netfang"
              errorText="Þetta er ekki gilt netfang"
              label="Netfang"
              value={email}
              validator={isEmail}
              minLength={0}
              handleChange={onEmailChange}
            />
          </Grid>
        </Grid>
      </Panel>

      <PartnerUserRoles handleRoleChange={handleRoleChange} />

      <Panel label="">
        {errorMessage && (
          <Grid container justifyContent="flex-end" spacing={2} sx={{ mb: 2 }}>
            <Banner type="error" onDismissed={onDismissError}>
              {errorMessage}
            </Banner>
          </Grid>
        )}
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" sx={styles.cancelButton} onClick={onCancel}>
              Hætta við
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={isSaving || !isAllowedToSave || !isStateValid()}
              sx={styles.confirmButton}
              onClick={handleSaveChanges}
            >
              Vista
            </Button>
          </Grid>
        </Grid>
      </Panel>
    </>
  );
}
