import React, { useState } from 'react';
import CustomPanel from 'src/common/UI/layouts/CustomPanel';
import { RadioButton } from '@motusehf/web-ui-component-library/dist';
import { styled } from '@mui/material';
import { api } from 'pages/api/trpc/_api';
import { noCacheAndNoRefetchOptions } from 'src/common/utils/noCacheAndNoRefetchOptions';
import Banner from 'src/common/UI/banners/Banner';

interface PartnerUserRolesProps {
  userId?: string;
  handleRoleChange: (value: string) => void;
}
const GroupContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 0),
}));

export default function PartnerUserRoles({ userId, handleRoleChange }: PartnerUserRolesProps) {
  const rolesGroupsState = api.rolegroups.getRoleGroups.useQuery();
  const userState = userId ? api.users.getUser.useQuery(userId, noCacheAndNoRefetchOptions) : undefined;
  const [errorMessage, setErrorMessage] = useState('');

  // If rolesGroupsState is not ready, return null
  if (rolesGroupsState.isLoading || rolesGroupsState.isError || !rolesGroupsState.data) return null;

  //Filter by the ClaimCreation domain to get the available roles for the user
  const claimCreation = rolesGroupsState.data.find((item) => item.domain === 'ClaimCreation');
  //Find the default value for the radio button by checking if the user already has a role starting with 'Claim'
  const defaultValue = userState?.data?.roles?.find((role: string) => role.startsWith('Claim')) ?? null;

  const options =
    claimCreation?.roles?.map((role) => ({
      value: role.name,
      label: role.display,
    })) || [];

  // Add "Nothing selected" option at the beginning of the options array
  options.push({ value: '', label: 'Engin aðgangur' });

  const renderOption = ({ children, index }: { children: React.ReactNode; index: number }) => {
    const backgroundColor = index % 2 === 0 ? '#EBEEED' : '#FAFAFA';
    return <div style={{ backgroundColor }}>{children}</div>;
  };

  const onDismissError = () => {
    setErrorMessage('');
  };

  return (
    <CustomPanel label="Heimild" mb={5} mt={1} borderColor="transparent">
      {errorMessage && (
        <Banner
          sx={{
            marginBottom: 3,
          }}
          type="warning"
          onDismissed={onDismissError}
        >
          {errorMessage}
        </Banner>
      )}
      <GroupContainer>
        <RadioButton
          options={options}
          onChange={handleRoleChange}
          style={{
            padding: '20px',
            width: '100%',
          }}
          renderOption={renderOption}
          defaultValue={defaultValue}
        />
      </GroupContainer>
    </CustomPanel>
  );
}
