import { SearchInputOption } from '../UI/forms/SearchInput';
import { formatSsn } from '../utils/ssnUtil';

export interface INationalRegistryEntry extends SearchInputOption {
  ssn: string;
  name: string;
  address: string;
}

export default class NationalRegistryEntry implements INationalRegistryEntry {
  constructor() {
    this.name = '';
    this.ssn = '';
    this.address = '';
  }

  ssn: string;
  name: string;
  address: string;
  getKey = (): string => this.ssn;
  getLabel = (): string => `${this.name} (kt. ${formatSsn(this.ssn)})`;
  compare = (other: SearchInputOption): boolean => {
    return this.ssn === (other as NationalRegistryEntry).ssn;
  };
}
