import * as React from 'react';
import { Chip } from '@mui/material';
import { GetSxForState, ActivityState } from '../../models/ActivityState';

interface Props {
  label: string;
  activityState: ActivityState;
}

export default function StatusChip({ label, activityState }: Props) {
  return <Chip label={label} sx={GetSxForState(activityState)} size="small" />;
}
