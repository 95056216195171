import { getSession } from 'next-auth/react';
import { ApiResponseError } from 'src/common/errors/ApiResponseError';

export default async function fetcher<T>(route: string, options: RequestInit | undefined): Promise<T> {
  const session = await getSession();
  const response = await fetch(getUrl(route), {
    ...options,
    credentials: 'include',
    headers: { ...options?.headers, Authorization: 'Bearer ' + session?.accessToken },
  });

  const isJson = response.headers.get('content-type')?.includes('application/json');
  const data = isJson ? await response.json() : null;

  if (response.status == 404) throw new ApiResponseError('Gögn fundust ekki');

  if (!response.ok) throw new ApiResponseError('Hér hefur eitthvað farið úrskeiðis');

  return data;
}

function getUrl(route: string): string {
  const urlPrefix = typeof window === 'undefined' ? `${process.env.API_URL}` : '';
  return urlPrefix + '/' + route;
}
