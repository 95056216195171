import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import constants from 'src/common/utils/constants';

export default function usePermissions() {
  const [isAllowedToSave, setIsAllowedToSave] = useState(false);
  const [hasAccessToApp, setHasAccessToApp] = useState(false);
  const [hasAccessToMimic, setHasAccessToMimic] = useState(false);
  const [hasAdminAccess, setHasAdminAccess] = useState(false);
  const { data: session, status: status } = useSession();

  useEffect(() => {
    if (session?.user?.roles) {
      setIsAllowedToSave(
        session.user.roles.includes(constants.ROLE_READWRITE) || session.user.roles.includes(constants.ROLE_ADMIN)
      );
      setHasAccessToApp(
        session.user.roles.some((role) => role === constants.ROLE_READ || role === constants.ROLE_READWRITE)
      );
      setHasAccessToMimic(session.user.roles.includes(constants.ROLE_MIMIC));
      setHasAdminAccess(session.user.roles.includes(constants.ROLE_ADMIN));
    }
  }, [session?.user?.roles]);

  return {
    isAllowedToSave,
    hasAccessToApp,
    hasAccessToMimic,
    hasAdminAccess,
    session,
    status,
  };
}
