import { useState, useEffect } from 'react';
import Banner from 'src/common/UI/banners/Banner';
import CheckboxControl, { rolesGroupsToCheckboxGroups } from 'src/common/UI/forms/CheckboxControl';
import RolesGroup from 'src/users/models/RolesGroup';
import LoadingSpinner from 'src/common/UI/loading/LoadingSpinner';
import usePermissions from 'src/common/hooks/authenticationHook';
import { api } from 'pages/api/trpc/_api';
import { noCacheAndNoRefetchOptions } from 'src/common/utils/noCacheAndNoRefetchOptions';
import CustomPanel from 'src/common/UI/layouts/CustomPanel';

interface EditAccessPrivilegesProps {
  userId?: string;
  organizationId?: string;
  hasPresetPrivileges?: boolean;
  checkboxKeys: string[];
  handleCheckboxChange: (keys: string[], isChecked: boolean) => void;
}

export default function AccessPrivilegesPanel({
  userId,
  hasPresetPrivileges,
  checkboxKeys,
  handleCheckboxChange,
  organizationId,
}: EditAccessPrivilegesProps): JSX.Element {
  const [isConfigured, setIsConfigured] = useState(hasPresetPrivileges);
  const rolesGroupsState = api.rolegroups.getRoleGroups.useQuery(undefined, noCacheAndNoRefetchOptions);
  const userState = userId ? api.users.getUser.useQuery(userId, noCacheAndNoRefetchOptions) : undefined;
  const organizationState = api.organizations.getOrganization.useQuery(organizationId || '', {
    ...noCacheAndNoRefetchOptions,
    enabled: !!organizationId,
  });
  const { isAllowedToSave } = usePermissions();
  const [errorMessage, setErrorMessage] = useState('');
  const hasUserCollectionAccess = userState?.data?.organization?.hasAccessToCollection;
  const hasOrganizationAccess = organizationState?.data?.hasAccessToCollection;
  const hasAccess = hasUserCollectionAccess ?? hasOrganizationAccess;

  useEffect(() => {
    if (hasAccess === false) {
      setErrorMessage('Þetta félag er ekki með aðgang að vörunni Innheimta');
    } else {
      setErrorMessage('');
    }
  }, [hasAccess]);

  const onDismissError = () => {
    setErrorMessage('');
  };

  // Set default checkboxes once the roles groups have been fetched on first render
  useEffect(() => {
    if (rolesGroupsState.isLoading || rolesGroupsState.isError || !rolesGroupsState.data || isConfigured || !hasAccess)
      return;
    handleCheckboxChange(GetDefaultCheckboxKeys(rolesGroupsState.data), true);
    setIsConfigured(true);
  }, [rolesGroupsState, isConfigured, setIsConfigured, handleCheckboxChange]);

  if ((rolesGroupsState.isLoading || !isConfigured) && hasAccess) {
    return <LoadingSpinner />;
  } else if (rolesGroupsState.isError) {
    return <Banner type="error">{rolesGroupsState.error.message}</Banner>;
  }
  return (
    <CustomPanel label="Heimildir í innheimtu" mb={5} mt={1} borderColor="transparent">
      {errorMessage && (
        <Banner
          sx={{
            marginBottom: 3,
          }}
          type="warning"
          onDismissed={onDismissError}
        >
          {errorMessage}
        </Banner>
      )}
      <CheckboxControl
        hasUserCollectionAccess={hasUserCollectionAccess}
        hasAccess={hasAccess}
        checkBoxGroups={rolesGroupsToCheckboxGroups(
          rolesGroupsState.data ?? [],
          checkboxKeys,
          isAllowedToSave,
          hasAccess
        )}
        handleCheckboxChange={handleCheckboxChange}
      />
    </CustomPanel>
  );
}

function GetDefaultCheckboxKeys(rolesGroups: RolesGroup[]): string[] {
  let defaultCheckboxKeys: string[] = [];
  rolesGroups
    .filter((rolesGroup) => rolesGroup.isDefault)
    .forEach(
      (defaultRolesGroup) =>
        (defaultCheckboxKeys = defaultCheckboxKeys.concat(defaultRolesGroup.roles.map((role) => role.name)))
    );
  return defaultCheckboxKeys;
}
