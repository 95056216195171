import ISearchSuggestionService from '../../contracts/searchSuggestionService';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import SearchAutoComplete from './SearchAutoComplete';
import { Typography } from '@mui/material';
import usePermissions from 'src/common/hooks/authenticationHook';

export interface SearchInputOption {
  getKey: () => string;
  getLabel: () => string;
  compare: <T extends SearchInputOption>(other: T) => boolean;
}

interface SearchInputProps<T> {
  searchSuggestionService: ISearchSuggestionService<T>;
  initialValue?: T | undefined;
  onValue: (value: T | null, suggestions: T[] | null) => void;
  label: string;
  placeholder?: string;
  hasAutoSelect?: boolean;
  ignoreEventsOnTarget?: string;
  renderSuggestion?: (option: T) => ReactNode;
}

const SearchInput = <T extends SearchInputOption>({
  searchSuggestionService,
  initialValue,
  onValue,
  label,
  placeholder,
  hasAutoSelect = true,
  ignoreEventsOnTarget,
  renderSuggestion = (option: T) => <Typography variant="body2">{option.getLabel()}</Typography>,
}: SearchInputProps<T>) => {
  const [value, setValue] = useState<T | null>(initialValue ?? null);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<T[]>([]);
  const { isAllowedToSave } = usePermissions();
  const debouncedfetchSearchResults = useMemo(
    () =>
      debounce(async (input: string) => {
        setIsLoading(true);
        const suggestions = await searchSuggestionService.getSuggestions(input);
        setIsLoading(false);
        setSuggestions(suggestions);
      }, 300),
    [searchSuggestionService]
  );

  useEffect(() => {
    onValue(value, suggestions);
  }, [value]);

  useEffect(() => {
    if (!inputValue) {
      setSuggestions(value ? [value] : []);
    } else {
      debouncedfetchSearchResults(inputValue);
    }
  }, [value, inputValue, debouncedfetchSearchResults]);

  return (
    <SearchAutoComplete<T>
      suggestions={suggestions}
      inputValue={inputValue}
      value={value}
      isLoading={isLoading}
      label={label}
      placeholder={placeholder}
      hasAutoSelect={hasAutoSelect}
      isDisabled={!isAllowedToSave}
      ignoreEventsOnTarget={ignoreEventsOnTarget}
      handleSetInputValue={setInputValue}
      handleSetValue={setValue}
      handleSetSuggestions={setSuggestions}
      renderSuggestion={renderSuggestion}
    />
  );
};

export default SearchInput;
