import * as React from 'react';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid, styled } from '@mui/material';
import { toast } from 'react-toastify';
import LoadingSpinner from 'src/common/UI/loading/LoadingSpinner';
import StatusChip from 'src/common/UI/dataDisplay/StatusChip';
import TextInput from 'src/common/UI/forms/TextInput';
import Panel from 'src/common/UI/layouts/Panel';
import { formatSsn } from 'src/common/utils/ssnUtil';
import Banner from 'src/common/UI/banners/Banner';
import ToastSuccess from 'src/common/UI/toasts/ToastSuccess';
import usePermissions from 'src/common/hooks/authenticationHook';
import { ModalCancelControl } from 'src/common/UI/layouts/ModalCancelControl';
import { api, trpcClient } from 'pages/api/trpc/_api';
import { noCacheAndNoRefetchOptions } from 'src/common/utils/noCacheAndNoRefetchOptions';
import { Partner } from 'src/partners/models/Partner';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OrganizationSuggestion } from 'src/organizations/models/OrganizationSearchSuggestion';
import SearchInput from 'src/common/UI/forms/SearchInput';
import OrganizationSuggestionService from 'src/organizations/services/organizationSuggestionService';
import { PartnerOrganization } from 'src/partners/models/PartnerOrganization';
import { UpdatePartnerRequest } from 'src/partners/models/UpdatePartnerRequest';
import DeleteButton from 'src/common/UI/buttons/DeleteButton';

const styles = {
  confirmButton: {
    backgroundColor: '#375046',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '8px',
    padding: '10px 22px 11px 25px',
  },
  cancelButton: {
    color: '#666666',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '7px',
    border: '1px solid #666666',
    padding: '10px 16px',
  },
  foreignLink: {
    color: '#73AF69',
    fontWeight: 400,
    textDecoration: 'none',
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
};

interface Props {
  partnerId: string;
  onSuccess: () => void;
  onFailure: () => void;
  onSubmit: () => void;
  modalCancelControl: ModalCancelControl;
}

export default function EditPartnersForm({ partnerId, onSuccess, onFailure, modalCancelControl, onSubmit }: Props) {
  const [description, setDescription] = useState<string>('');
  const [organizations, setOrganization] = useState<PartnerOrganization[]>([]);
  const [name, setName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const { isAllowedToSave } = usePermissions();
  const [noOrgErrorMessage, setNoOrgErrorMessage] = useState('');
  const partnerState = api.partners.getPartners.useQuery(partnerId, noCacheAndNoRefetchOptions);

  const organizationSearchService = new OrganizationSuggestionService();

  useEffect(() => {
    if (partnerState.data) {
      setDescription(partnerState.data.description);
      setName(partnerState.data.name);
      setOrganization(partnerState.data.organizations);
    }
  }, [partnerState.data]);

  useEffect(() => {
    if (organizations.length === 0 && noOrgErrorMessage === '') {
      setNoOrgErrorMessage('Enginn eigandi hefur veitt þessum aðila heimild að sínum kröfum.');
    } else if (organizations.length !== 0 && noOrgErrorMessage !== '') {
      setNoOrgErrorMessage('');
    }
  }, [organizations, noOrgErrorMessage]);

  const forceCancelEdit = () => {
    modalCancelControl.isSafe = true;
    modalCancelControl.closeFunc();
  };

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setDescription(event.target.value);
  };

  const handleAddOrganization = (value: OrganizationSuggestion | null): void => {
    if (organizations.find((org) => org.id === value?.id)) {
      return;
    } else {
      if (!value) return;
      const newOrg: PartnerOrganization = {
        id: value.id,
        name: value?.name,
        ssn: value?.ssn,
        description: '',
        isActive: true,
        claimTemplates: [],
        claimants: [],
      };
      setOrganization((state: PartnerOrganization[]) => [...state, newOrg]);
    }
  };

  const handleRemoveOrganization = (e: React.SyntheticEvent | null, id: string): void => {
    e ? e.stopPropagation() : null;
    setOrganization((state: PartnerOrganization[]) => state.filter((org) => org.id !== id));
  };

  if (partnerState.isLoading) {
    return <LoadingSpinner></LoadingSpinner>;
  } else if (partnerState.isError || !partnerState.data) {
    return <Banner type="error">{partnerState.error?.message}</Banner>;
  }

  const isStateValid = () => {
    return !partnerState.data;
  };

  const hasDataBeenModified = () => {
    const hasBeenModified = description !== partnerState.data?.description || name !== partnerState.data?.name;
    const hasOrganizationsBeenModified =
      organizations.length !== partnerState.data?.organizations.length ||
      organizations.some((org) => {
        const foundOrg = partnerState.data?.organizations.find((pOrg) => pOrg.id === org.id);
        return !foundOrg || foundOrg.name !== org.name || foundOrg.ssn !== org.ssn;
      });
    if (hasBeenModified) {
      modalCancelControl.isSafe = false;
    } else {
      modalCancelControl.isSafe = true;
    }
    return hasBeenModified || hasOrganizationsBeenModified;
  };

  const savePartner = (): void => {
    if (!partnerState.data) setErrorMessage('Samstarfsaðila gögn tóm');
    if (!isAllowedToSave) return;
    if (isSaving) return;
    setIsSaving(true);
    onSubmit();
    const updateRequest: UpdatePartnerRequest = {
      ...(partnerState.data as Partner),
      description,
      organizations: organizations.map((org) => org.id),
    };
    trpcClient.partners.updatePartner
      .mutate(updateRequest)
      .then(
        () => {
          toast(<ToastSuccess message={'Samstarfsaðili hefur verið uppfærður.'} />);
          onSuccess();
        },
        () => {
          setErrorMessage('Hér hefur eitthvað farið úrskeiðis');
          onFailure();
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  const StyledAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  }));

  const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    marginBottom: -1,
    '&$expanded': {
      minHeight: 56,
    },
    flexDirection: 'row-reverse',
  }));

  return (
    <>
      <Box sx={{ mb: -3 }}>
        <Typography variant="h1" display="inline" sx={{ marginRight: '12px' }}>
          {partnerState.data.name}
        </Typography>
        <StatusChip label="Virkur" activityState={'Active'} />
      </Box>
      <Panel label="Aðili">
        <Box sx={{ mb: 3 }} />
        <Grid container rowSpacing="34px" columnSpacing="128px">
          <Grid item xs={12} md={6}>
            <Typography>Nafn</Typography>
            <Typography fontWeight={100}>{partnerState.data.name}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              disabled={!isAllowedToSave}
              label="Skrá lýsingu"
              onChange={onDescriptionChange}
              value={description}
            />
          </Grid>
        </Grid>
      </Panel>

      <Panel label="Tengist">
        <Box sx={{ mb: 3 }} />
        <Grid item xs={12} md={6} mb={4}>
          <SearchInput<OrganizationSuggestion>
            searchSuggestionService={organizationSearchService}
            onValue={handleAddOrganization}
            label="Sláðu inn nafn eða kennitölu eiganda"
            hasAutoSelect={false}
          />
        </Grid>
        <Grid>
          <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
            {noOrgErrorMessage ? (
              <Banner type="info" isDismissible={false}>
                {noOrgErrorMessage}
              </Banner>
            ) : (
              organizations.map((organization: Partner['organizations'][number], index: number) => (
                <StyledAccordion
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? '#EBEEED' : '#FAFAFA',
                    margin: 0,
                  }}
                >
                  <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}a-content`}
                    id={`panel${index}a-header`}
                    style={{
                      backgroundColor: index % 2 === 0 ? '#EBEEED' : '#FAFAFA',
                      margin: 0,
                    }}
                  >
                    <Typography style={{ paddingLeft: '16px' }}>{`${organization.name} (${formatSsn(
                      organization.ssn
                    )})`}</Typography>
                    <Typography variant="body2" style={{ marginLeft: '10px' }}>
                      ({organization.claimTemplates.length} auðkenni)
                    </Typography>
                    <DeleteButton
                      sx={{ p: 0, ml: 'auto' }}
                      isDisabled={!isAllowedToSave}
                      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                        handleRemoveOrganization(e, organization.id)
                      }
                    />
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1}>
                      {organization.claimTemplates.map(
                        (
                          template: Partner['organizations'][number]['claimTemplates'][number],
                          templateIndex: number
                        ) => (
                          <Grid item xs={12} md={6} key={templateIndex}>
                            <Box p={1}>
                              <Typography variant="body2">
                                {`${template.name} - (${formatSsn(template.claimantSsn)})`}
                              </Typography>
                            </Box>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </AccordionDetails>
                </StyledAccordion>
              ))
            )}
          </Grid>
        </Grid>
      </Panel>

      <Panel label="">
        {errorMessage && (
          <Grid container justifyContent="flex-end" spacing={2} sx={{ mb: 2 }}>
            <Banner type="error">{errorMessage}</Banner>
          </Grid>
        )}
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" sx={styles.cancelButton} onClick={forceCancelEdit} disabled={isSaving}>
              Hætta við
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={styles.confirmButton}
              disabled={isSaving || (!hasDataBeenModified() && !isStateValid()) || !isAllowedToSave}
              onClick={savePartner}
            >
              Vista
            </Button>
          </Grid>
        </Grid>
      </Panel>
    </>
  );
}
