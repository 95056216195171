import { Alert, Collapse, SxProps, Theme, Typography } from '@mui/material';
import { useState } from 'react';
import { EmptyFunction } from 'src/common/utils/emptyFunction';
import BannerIcon from './BannerIcon';
import BannerType from './bannerType';

interface BannerProps {
  type: BannerType;
  isDismissible?: boolean;
  children: React.ReactNode;
  onDismissed?: () => void;
  sx?: SxProps<Theme> | undefined;
}

export default function Banner({ children, type, isDismissible = true, onDismissed = EmptyFunction, sx }: BannerProps) {
  const [isDismissed, setIsDismissed] = useState(false);

  let bannerBody;
  const isSingleMessage = !children || typeof children === 'string';
  if (isSingleMessage) {
    bannerBody = (
      <Typography variant="body2" fontSize="14px">
        {children ?? 'Hér hefur eitthvað farið úrskeiðis'}
      </Typography>
    );
  } else {
    bannerBody = children;
  }

  const styles = {
    alert: {
      '& .MuiAlert-icon': {
        margin: 0,
        padding: 0,
        paddingRight: '12px',
        '& .MuiBox-root': {
          height: '40px',
          width: '40px',
        },
      },
      '& .MuiAlert-message': {
        padding: isSingleMessage ? 'auto' : 0,
        margin: isSingleMessage ? 'auto' : 0,
        marginLeft: '16px',
      },
      paddingLeft: '8px',
      backgroundColor: 'white',
      color: 'basicblack.main',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25);',
      ...sx,
    },
  };

  const timeout = 300;

  return (
    <Collapse in={!isDismissed} timeout={timeout}>
      <Alert
        icon={<BannerIcon type={type} />}
        severity={'error'}
        variant="filled"
        sx={styles.alert}
        onClose={
          isDismissible
            ? () => {
                setIsDismissed(true);
                setTimeout(onDismissed, timeout);
              }
            : undefined
        }
      >
        {bannerBody}
      </Alert>
    </Collapse>
  );
}
