import * as React from 'react';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Box, Button, Theme, Tooltip } from '@mui/material';
import CustomButton from 'src/common/UI/forms/CustomButton';
import StatusIcon from 'src/common/UI/dataDisplay/StatusIcon';
import { Partner } from 'src/partners/models/Partner';
import PartnerCount from './PartnerCount';

interface TableProps {
  partners: Partner[];
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectPartner: (id: string) => void;
  onFetchMorePartners: () => void;
  isLoadingMorePartners: boolean;
  shouldShowFetchMore: boolean;
  partnerCount: number;
}

const TableCellStyled = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  ...theme.typography.body1,
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  pb: 1,
  pt: 1,
}));

export default function PartnersTable(props: TableProps): JSX.Element {
  return (
    <>
      <TableContainer component={Paper} sx={{ padding: '0px 24px' }}>
        <Table aria-label="partner table" size="small">
          <TableHead>
            <TableRow>
              <TableCellStyled align="left" sx={{ width: '10%' }}>
                <Typography variant="body1">Aðili</Typography>
              </TableCellStyled>
              <TableCellStyled align="left" sx={{ width: '57%' }}>
                <TextField
                  sx={{ width: '80%' }}
                  id="outlined-basic"
                  label="Leit"
                  variant="outlined"
                  size="small"
                  onChange={props.handleInputChange}
                />
              </TableCellStyled>
              <TableCellStyled sx={{ width: '8%' }}>
                <FormControl variant="standard" sx={{ mb: 2 }}>
                  <InputLabel>
                    <Typography variant="body1">Staða</Typography>
                  </InputLabel>
                  <Select
                    labelId="select-partner-stada-label"
                    id="select-partner-stada"
                    label="staða"
                    defaultValue=""
                    disableUnderline
                    IconComponent={() => null} // Þetta tekur út dropdown arrow
                    disabled // Disablar dropdown
                  >
                    <MenuItem value="">Allir</MenuItem>
                    <MenuItem value={10}>Virkur</MenuItem>
                    <MenuItem value={20}>Óvirkur</MenuItem>
                  </Select>
                </FormControl>
              </TableCellStyled>
              <TableCellStyled sx={{ width: '10%', textAlign: 'center' }}></TableCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.partners.map((row) => (
              <TableRow key={row.id + '-' + row.name}>
                <TableCellStyled
                  align="left"
                  style={{ minWidth: '250px', maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  <Button
                    onClick={() => props.selectPartner(row.id ?? '')}
                    style={{ textTransform: 'none', padding: 0, minWidth: 0 }}
                  >
                    <Typography variant="body1" display="inline" sx={{ whiteSpace: 'nowrap' }}>
                      {row.name}
                    </Typography>
                  </Button>
                </TableCellStyled>
                <TableCellStyled align="left">
                  <Button
                    onClick={() => props.selectPartner(row.id ?? '')}
                    style={{ textTransform: 'none', padding: 0, minWidth: 0 }}
                  >
                    <Typography variant="body2" display="inline"></Typography>
                  </Button>
                </TableCellStyled>

                <TableCellStyled align="right">
                  <Typography>
                    <StatusIcon activityState="Active" />
                  </Typography>
                </TableCellStyled>

                <TableCellStyled align="center" sx={{ textAlign: 'center' }}>
                  <Tooltip title="Opna yfirlit">
                    <IconButton onClick={() => props.selectPartner(row.id)}>
                      <ArrowCircleRightOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </TableCellStyled>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {props.shouldShowFetchMore ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 110,
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: '17px',
                bottom: '42px',
              }}
            >
              <PartnerCount partnerCount={props.partnerCount} />
            </Box>
            <CustomButton isLoading={props.isLoadingMorePartners} onClick={props.onFetchMorePartners}>
              SÆKJA FLEIRI
            </CustomButton>
          </Box>
        ) : null}
      </TableContainer>
    </>
  );
}
