import ISearchSuggestionService from 'src/common/contracts/searchSuggestionService';
import { trpcClient } from 'pages/api/trpc/_api';
import { OrganizationSuggestion } from '../models/OrganizationSearchSuggestion';
import SearchResponse from 'src/users/models/SearchResponse';
import { Organization } from '../models/Organization';

export default class OrganizationSuggestionService implements ISearchSuggestionService<OrganizationSuggestion> {
  async getSuggestions(searchInput: string): Promise<OrganizationSuggestion[]> {
    return await trpcClient.organizations.searchOrganizations
      .query({ searchString: searchInput, page: 0, pageSize: 7, shouldSearchClaimants: false })
      .then(
        (data: SearchResponse<Organization>) => {
          return data.hits.map((x) => {
            const org = new OrganizationSuggestion();
            org.id = x.id;
            org.ssn = x.ssn ?? '';
            org.name = x.name;
            return org;
          });
        },
        (error) => error
      );
  }
}
