import { IconButton, Tooltip } from '@mui/material';
import usePermissions from 'src/common/hooks/authenticationHook';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';

interface MimicUserButtonProps {
  userId: string;
}

export default function MimicUserButton({ userId }: MimicUserButtonProps) {
  const { session, hasAccessToMimic } = usePermissions();

  function getUrl() {
    const url = process.env.NEXT_PUBLIC_VIDSKIPTAVEFUR_URL as string;
    const token = session?.accessToken;
    return `${url}?token=${token}&mimicId=${userId}`;
  }

  return (
    <Tooltip title="Tengjast Viðskiptavef sem þessi notandi">
      <span>
        <IconButton href={getUrl()} target="_blank" disabled={!hasAccessToMimic}>
          <SupervisorAccountOutlinedIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
}
