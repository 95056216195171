import ISearchSuggestionService from 'src/common/contracts/searchSuggestionService';
import NationalRegistryEntry, { INationalRegistryEntry } from '../models/NationalRegistryEntry';
import SearchResponse from '../../users/models/SearchResponse';
import fetcher from 'src/common/utils/fetcher';

export default class NationalRegistryService implements ISearchSuggestionService<NationalRegistryEntry> {
  async getSuggestions(searchInput: string): Promise<INationalRegistryEntry[]> {
    return await fetcher<SearchResponse<INationalRegistryEntry>>(`api/thjodskra/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        pageSize: 7,
        page: 0,
        search: searchInput,
      }),
    }).then(
      (data) => {
        return data.hits.map((x) => {
          const e = new NationalRegistryEntry();
          e.name = x.name;
          e.ssn = x.ssn;
          e.address = x.address;
          return e;
        });
      },
      (error) => error
    );
  }
}
