import * as React from 'react';
import { Typography, styled } from '@mui/material';
import RolesGroup from 'src/users/models/RolesGroup';
import Checkbox from './Checkbox';

interface CheckboxProps {
  checkBoxGroups: IndeterminateCheckboxProps[];
  handleCheckboxChange: (keys: string[], isChecked: boolean) => void;
  hasUserCollectionAccess?: boolean;
  hasAccess?: boolean;
}
interface IndeterminateCheckboxProps {
  groupName: string;
  checkboxes: LabeledCheckBox[];
  isAllowedToSave: boolean;
}
interface LabeledCheckBox {
  key: string;
  label: string;
  isChecked: boolean;
}

export function rolesGroupsToCheckboxGroups(
  rolesGroups: RolesGroup[],
  checkboxKeys: string[],
  isAllowedToSave: boolean,
  hasAccess: boolean
): IndeterminateCheckboxProps[] {
  const filteredRolesGroups = rolesGroups.filter((rolesGroup) => rolesGroup.domain !== 'ClaimCreation');
  return filteredRolesGroups.map(function RoleGroupToCheckboxGroup(rolesGroup) {
    const checkBoxGroup: IndeterminateCheckboxProps = {
      groupName: rolesGroup.description,
      checkboxes: rolesGroup.roles.map(function (role) {
        const labeledCheckBox: LabeledCheckBox = {
          key: role.name,
          label: role.display,
          isChecked: hasAccess && checkboxKeys.indexOf(role.name) > -1,
        };
        return labeledCheckBox;
      }),
      isAllowedToSave: isAllowedToSave,
    };
    return checkBoxGroup;
  });
}

const GroupContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr', // 1/3 for the group name, 2/3 for the checkboxes
  alignItems: 'center',
  padding: theme.spacing(1.5, 4),
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const GroupTitle = styled(Typography)({
  fontWeight: 'bold',
});

const CheckboxContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  gap: '16px',
  alignItems: 'center',
});

const CheckboxLabel = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export default function CheckboxControl({ checkBoxGroups, handleCheckboxChange, hasAccess }: CheckboxProps) {
  return (
    <div>
      {checkBoxGroups.map((group, groupIndex) => (
        <GroupContainer
          key={group.groupName}
          style={{
            backgroundColor: groupIndex % 2 === 0 ? '#EBEEED' : '#FAFAFA',
            opacity: !hasAccess || !group.isAllowedToSave ? 0.5 : 1,
          }}
        >
          <GroupTitle>{group.groupName}</GroupTitle>
          <CheckboxContainer>
            {group.checkboxes.map((checkbox) => (
              <div key={checkbox.key} style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={checkbox.isChecked}
                  onChange={(e) => handleCheckboxChange([checkbox.key], e.target.checked)}
                  disabled={!group.isAllowedToSave || !hasAccess}
                />
                <CheckboxLabel
                  variant="body2"
                  style={{
                    padding: '20px',
                    width: '100%',
                  }}
                >
                  {checkbox.label}
                </CheckboxLabel>
              </div>
            ))}
          </CheckboxContainer>
        </GroupContainer>
      ))}
    </div>
  );
}
