import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { Box, styled, SvgIcon } from '@mui/material';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

interface ThinBoxProps {
  children?: React.ReactNode;
}

const styles = {
  thinBox: {
    width: '24px',
    height: '24px',
    border: 1,
    borderColor: 'primary',
    color: 'primary',
    backgroundColor: 'white',
    borderRadius: '2px',
    margin: '3px',
  },
};

export default styled((props: CheckboxProps) => (
  <Checkbox
    {...props}
    icon={<ThinBox />}
    checkedIcon={
      <ThinBox>
        <SvgIcon
          color="primary"
          fontSize="small"
          stroke="#375046"
          strokeWidth="1"
          component={CheckOutlinedIcon}
        ></SvgIcon>
      </ThinBox>
    }
    indeterminateIcon={
      <ThinBox>
        <SvgIcon
          color="primary"
          fontSize="small"
          stroke="#375046"
          strokeWidth="1.5"
          component={RemoveOutlinedIcon}
        ></SvgIcon>
      </ThinBox>
    }
  />
))();

function ThinBox({ children }: ThinBoxProps) {
  return <Box sx={styles.thinBox}>{children}</Box>;
}
