import { Divider, Grid, Typography } from '@mui/material';

interface CustomPanelProps {
  children: React.ReactNode;
  label?: string;
  mt?: number;
  mb?: number;
  borderColor?: string;
}

export default function CustomPanel({ children, label, mt = 8, mb = 5, borderColor }: CustomPanelProps) {
  return (
    <>
      <Divider sx={{ mt, mb, borderColor }} />
      <Grid container columns={{ xs: 5 }} sx={{ minWidth: '600px' }}>
        {label && (
          <Grid item xs={1}>
            <Typography variant="h4" component="h3">
              {label}
            </Typography>
          </Grid>
        )}
        <Grid item xs={4}>
          {children}
        </Grid>
      </Grid>
    </>
  );
}
