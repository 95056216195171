import ISearchSuggestionService from 'src/common/contracts/searchSuggestionService';
import { IncludeOrphants } from 'src/common/models/IncludeOrphants';
import SearchResponse from '../models/SearchResponse';
import Claimant, { IClaimant } from '../models/Claimant';
import { formatSsn, isSSN } from 'src/common/utils/ssnUtil';
import { trpcClient } from 'pages/api/trpc/_api';

export default class ClaimantSearchService implements ISearchSuggestionService<Claimant> {
  private includeOrphants: IncludeOrphants;

  constructor(includeOrphants: IncludeOrphants) {
    this.includeOrphants = includeOrphants;
  }

  async getSuggestions(searchInput: string): Promise<Claimant[]> {
    return await trpcClient.claimants.searchClaimants
      .query({
        searchString: searchInput,
        includeOrphans: this.includeOrphants,
      })
      .then(
        (data) => {
          return mapData(data, searchInput);
        },
        (error) => error
      );
  }
}

function mapData(data: SearchResponse<IClaimant>, searchInput: string) {
  let claimants = data.hits.map((x) => {
    const org = new Claimant();
    org.number = x.number;
    org.name = x.name;
    org.name2 = x.name2;
    org.ssn = x.ssn;
    org.organizationName = x.organizationName;
    org.organizationSsn = x.organizationSsn;
    org.organizationId = x.organizationId;
    return org;
  });
  claimants = addOrganizationTotalIfSSN(claimants, searchInput);
  return claimants;
}

function addOrganizationTotalIfSSN(suggestions: Claimant[], input: string) {
  if (isSSN(input) && suggestions.length > 2 && suggestions[0].name !== 'allir') {
    const countOfClaimants = suggestions.length;
    const fakeClaimant = new Claimant();
    fakeClaimant.number = `Samtals ${countOfClaimants} kröfuhafaspjöld`;
    fakeClaimant.ssn = input;
    fakeClaimant.name = 'Velja alla';
    fakeClaimant.getLabel = () => `${fakeClaimant.number} - (kt. ${formatSsn(fakeClaimant.ssn)})`;
    suggestions.unshift(fakeClaimant);
    return suggestions;
  }
  return suggestions;
}
