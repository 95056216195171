import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

interface HeaderProps {
  title: string;
}

export default function HeaderPage(Props: HeaderProps): JSX.Element {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Toolbar aria-label="PageHeader" disableGutters={true}>
        <Typography variant="h1">{Props.title}</Typography>
      </Toolbar>
    </Box>
  );
}
