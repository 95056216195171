import { IncludeOrphants } from 'src/common/models/IncludeOrphants';
import { formatSsn } from 'src/common/utils/ssnUtil';
import Claimant from 'src/users/models/Claimant';
import { ClaimantsOrganizationState } from './models';

export function getIncludeOrphants(
  isUpsertingUser: boolean,
  shouldOnlySearchForOrphantClaimants: boolean
): IncludeOrphants {
  if (isUpsertingUser) return IncludeOrphants.No;
  if (shouldOnlySearchForOrphantClaimants) return IncludeOrphants.Only;
  return IncludeOrphants.Yes;
}

export function getClaimantBelongsToOrgMessage(orgSsn?: string | null, orgName?: string | null): string {
  return `Þessi kröfuhafi tilheyrir þegar ${orgName} (kt. ${formatSsn(orgSsn)})`;
}

export function mapExpandedPanels(claimantSSNOs: string[], isExpanded: boolean) {
  const newExpandedPanels: { [index: string]: boolean } = {};
  for (let i = 0; i < claimantSSNOs.length; i++) {
    const index: string = claimantSSNOs[i];
    newExpandedPanels[index] = isExpanded;
  }

  return newExpandedPanels;
}

export function createClaimantsOrganizationState(claimants: Claimant[]) {
  const ssnDictionary: { [ssno: string]: ClaimantsOrganizationState } = {};
  claimants.forEach((claimant) => {
    if (claimant.ssn !== undefined) {
      const entry = {
        claimant: claimant,
        isRemoving: false,
      };
      if (claimant.ssn in ssnDictionary) {
        ssnDictionary[claimant.ssn].claimantStates.push(entry);
      } else {
        ssnDictionary[claimant.ssn] = { isRemoving: false, claimantStates: [entry] };
      }
    }
  });
  return ssnDictionary;
}

export function getAllClaimantStates(claimantsData: { [ssno: string]: ClaimantsOrganizationState }) {
  return Object.values(claimantsData)
    .map((x) => x.claimantStates)
    .flat();
}
