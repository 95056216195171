import { Grid, Typography } from '@mui/material';

interface UserInfoProps {
  title: string;
  info: JSX.Element | string;
}

export default function UserInfo({ title, info }: UserInfoProps) {
  const isSingleMessage = !info || typeof info === 'string';

  const infoBody = isSingleMessage ? (
    <Typography fontFamily="Brandon Text W01 Regular" fontWeight="medium">
      {info ?? 'Hér hefur eitthvað farið úrskeiðis'}
    </Typography>
  ) : (
    info
  );

  return (
    <Grid container spacing="4px" columns={{ xs: 1 }}>
      <Grid item xs={1}>
        <Typography fontFamily="Brandon Text W01 Medium" fontWeight="bold">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        {infoBody}
      </Grid>
    </Grid>
  );
}
