import { useEffect, useReducer } from 'react';
import { ApiResponseError } from '../errors/ApiResponseError';

export interface DataState<T> {
  isLoading: boolean;
  isError: boolean;
  data: T;
  errorMessage?: string;
}

enum ActionType {
  Initalized,
  Success,
  Failure,
}

interface ReducerAction<T> {
  type: ActionType;
  data?: T;
  errorMessage?: string;
}

function dataFetchReducer<T>(state: DataState<T>, action: ReducerAction<T>): DataState<T> {
  switch (action.type) {
    case ActionType.Initalized:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case ActionType.Success:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.data as T,
      };
    case ActionType.Failure:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.errorMessage,
      };
    default:
      throw new Error();
  }
}

export default function useDataApi<T>(
  fetchDataCallback: () => Promise<T>,
  initialData?: T
): [DataState<T>, (data: T) => void] {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: true,
    isError: false,
    data: initialData ?? {},
  });

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: ActionType.Initalized });

      try {
        const data = await fetchDataCallback();

        if (!didCancel) {
          dispatch({ type: ActionType.Success, data: data });
        }
      } catch (error) {
        if (!didCancel) {
          if (error instanceof ApiResponseError) {
            dispatch({ type: ActionType.Failure, errorMessage: error.message });
          } else {
            dispatch({
              type: ActionType.Failure,
              errorMessage: 'Hér hefur eitthvað farið úrskeiðis',
            });
          }
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, []);

  function setData(data: T) {
    dispatch({
      type: ActionType.Success,
      data: data,
    });
  }

  return [state as DataState<T>, setData];
}
