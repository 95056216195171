import { Box, Typography, IconButton, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { useSession, signOut } from 'next-auth/react';
import { useState } from 'react';
import Image from 'next/image';
import Router from 'next/router';
import { env } from 'src/common/env.mjs';

export default function AccountMenu({}) {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { data: session } = useSession();

  const handleOpenAccountMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseAccountMenu = (): void => {
    setAnchorElUser(null);
  };

  const handleLogout = (): void => {
    handleCloseAccountMenu();
    signOut({ redirect: false }).finally(() => {
      Router.push(`${env.STS_URL}/Account/Logout`);
    });
  };

  if (!session) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 1,
        textAlign: 'center',
        alignItems: 'center',
        mr: '6%',
        gap: '1.5em',
      }}
    >
      <Typography variant="body2">{session.user?.name}</Typography>
      <IconButton onClick={handleOpenAccountMenu} sx={{ p: 0, mr: 0.8 }}>
        <Image src="/icons/accountMenuIcon.svg" alt="menu" width="40" height="41" />
      </IconButton>
      <Menu
        sx={{ mt: 6, mr: 1 }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseAccountMenu}
      >
        <MenuItem key="header" sx={{ padding: '1em 2em' }} divider>
          <Typography variant="body1">{session.user?.name}</Typography>
        </MenuItem>
        <MenuItem key="logout" sx={{ padding: '0.8em 2em' }} onClick={handleLogout}>
          <ListItemIcon>
            <Image src="/icons/logoutIcon.svg" alt="Útskrá" width="24" height="24" />
          </ListItemIcon>
          <Typography variant="body2">Útskrá</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
