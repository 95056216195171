import { styled } from '@mui/material/styles';
import { Accordion as MuiAccordion, AccordionDetails, Theme } from '@mui/material';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiBox from '@mui/material/Box';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

interface AccordionCompositeProps {
  contents: string[];
  expandedPanels: { [index: string]: boolean };
  setExpandedPanels: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  hasAccess: boolean;
  summary: (obj: string) => React.ReactNode;
  details: (obj: string) => React.ReactNode;
  onPanelToggle: (
    panel: string,
    setExpandedPanels: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>,
    expandedPanels: { [index: string]: boolean }
  ) => (_event: React.SyntheticEvent, isExpanded: boolean) => void;
}

const Accordion = styled(MuiAccordion)(({ theme }: { theme: Theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(
  ({ theme }: { theme: Theme }) => ({
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(0deg)',
      transitionDuration: '0s',
    },
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
    borderTop: 0,
  })
);

const Box = styled(MuiBox)(() => ({
  border: `1px solid #000000`,
  borderRadius: '5px',
  height: '2rem',
  width: '2rem',
  color: '#000000',
  marginRight: '0.8rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const handleTogglePanel =
  (
    panel: string,
    setExpandedPanels: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>,
    expandedPanels: { [index: string]: boolean }
  ) =>
  (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedPanels({ ...expandedPanels, [panel]: isExpanded });
  };

export function areAllPanelsExpanded(expandedPanels: { [index: string]: boolean }) {
  if (Object.keys(expandedPanels).length === 0) return false;
  return Object.values(expandedPanels).every(Boolean);
}

export default function AccordionComposite({
  contents,
  summary,
  details,
  expandedPanels,
  setExpandedPanels,
  onPanelToggle,
  hasAccess,
}: AccordionCompositeProps) {
  if (contents.length === 0) {
    return null;
  }
  return (
    <>
      {contents.map((content: string, index: number) => (
        <Accordion
          disabled={!hasAccess}
          key={content}
          disableGutters
          elevation={0}
          expanded={expandedPanels[content] ? true : false}
          onChange={onPanelToggle(content, setExpandedPanels, expandedPanels)}
          sx={{ backgroundColor: index % 2 ? '#FAFAFA' : '#EBEEED' }}
        >
          <AccordionSummary
            id={`panel-${content}`}
            expandIcon={
              expandedPanels[content] ? (
                <Box>
                  <RemoveIcon sx={{ fontSize: '1rem' }} />
                </Box>
              ) : (
                <Box>
                  <AddIcon sx={{ fontSize: '1rem' }} />
                </Box>
              )
            }
          >
            {summary(content)}
          </AccordionSummary>
          <AccordionDetails>{details(content)}</AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
